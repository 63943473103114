<template>
  <transition name="Transition__fade">
    <div class="BaseItemUndo row row--align-center">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 BaseInvoiceItem__FooterOption__x-icon"
        tabindex="0"
      />
      <label class="BaseInvoiceItem__FooterOption__undo-lbl fs-14">
        {{ isBuyout ? 'Delete this invoice and associated buyout?' : 'Send this back to pending?' }}
      </label>

      <base-button
        @click="undoInvoice"
        class="bg-blue fc-white"
        data-cy="base-item-undo-confirm-btn"
        :disabled="buttonDisabled"
      >
        Yes
      </base-button>
    </div>
  </transition>
</template>

<script>
// Helpers
import { Invoice } from '../utils/api'
// Components
import BaseButton from './base-button.vue'

export default {
  name: 'BaseItemUndo',

  components: {
    BaseButton,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    isBuyout: {
      type: Boolean,
      required: false,
    },
    statusBackTo: {
      type: String,
      required: true,
    },
    buyout: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      buttonDisabled: false,
    }
  },

  methods: {
    closeModal () {
      this.$emit('close')
    },

    async undoInvoice () {
      this.buttonDisabled = true
      this.progressStart()

      try {
        await Invoice.update({
          approved_date: null,
          id: this.id,
          paid_date: null,
          status: this.statusBackTo,
        })
        this.$store.commit('DELETE_INVOICE', this.id)
        this.closeModal()
        this.progressFinish()
        this.buttonDisabled = false
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Undo "Undo" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the invoice' })
        this.buttonDisabled = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>