<template>
  <div
    :class="['BaseInvoiceItem', {
      'BaseInvoiceItem--flagged': dataInvoice.flagged && dataInvoice.last_flag_message,
      'BaseInvoiceItem--mobile': mobile.isMobile,
    }]"
    :data-cy="`base-invoice-item-${dataInvoice.load_number}`"
  >
    <!-- Invoice top-level information -->
    <div
      @click="expand"
      @keydown.enter="expand"
      :class="['BaseInvoiceItem__top-container BaseInvoiceItem__top-container--declined', {
        'BaseInvoiceItem__top-container--unclickable': !mobile.isMobile && userIsClient
      }]"
      tabindex="0"
    >
      <div class="BaseInvoiceItem__top-container--desktop row">
        <!-- Load # -->
        <div class="column">
          <label
            class="fs-14 fw-med width-100"
            :data-cy="`base-invoice-item-load-${dataInvoice.load_number}`"
            :title="dataInvoice.load_number"
          >
            <!-- The maximum # of characters allowed in this space is 10 (with ...) -->
            {{
              dataInvoice.load_number.length > 10
                ? `${dataInvoice.load_number.slice(0, 10)}...`
                : dataInvoice.load_number
            }}
          </label>
        </div>

        <!-- Flagged -->
        <div class="column">
          <label
            v-if="dataInvoice.flagged && dataInvoice.last_flag_message"
            class="BaseInvoiceItem__flag-lbl fs-12 uppercase bg-red"
            data-cy="base-invoice-item-flag"
          >
            {{ flagMessage }}
          </label>
          <!-- FLAG ICON - Displayed at the smallest of desktop resolutions to give debtor more room -->
          <div class="BaseInvoiceItem__flag-icon column mr-16">
            <i
              v-if="dataInvoice.flagged && dataInvoice.last_flag_message"
              class="fa fa-flag fc-red fa--none fs-18"
            />
          </div>
        </div>

        <!-- Debtor -->
        <div class="column">
          <!-- Having this column with a column allows the clickable range to only be on the
          actual debtor content (and outline for :focus) compared to being the entire debtor
          column width -->
          <div
            class="column column--width-auto"
            :title="
              dataInvoice.debtor.broker
                ? `MC: ${dataInvoice.debtor.broker.mc}`
                : 'MC: Not a broker'"
          >
            <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
            <label
              @click.stop="goToDebtorProfile"
              @keydown.enter.stop="goToDebtorProfile"
              :class="['fs-14 uppercase', {
                'clickable':
                  !userIsClient
                  && dataInvoice.debtor.id !== 2
                  && dataInvoice.debtor.name.toLowerCase() !== 'debtor not found'
              }]"
              :tabindex="!userIsClient ? 0 : null"
            >
              {{ sanitizeAlgoliaNames(dataInvoice.debtor_selected_name) }}
            </label>
            <label class="fc-light fs-12">{{ formatPhoneNumber(dataInvoice.debtor.phone) }}</label>
          </div>
        </div>

        <!-- Client -->
        <div
          v-if="!userIsClient"
          class="column"
        >
          <div
            class="column"
            :title="`MC: ${dataInvoice.client.mc}`"
          >
            <label
              @click.stop="goToClientProfile"
              @keydown.enter.stop="goToClientProfile"
              class="clickable fs-14"
              tabindex="0"
            >
              {{ dataInvoice.client.shortened_name }}
            </label>
            <label class="fc-light fs-12">{{ formatPhoneNumber(dataInvoice.client.phone) }}</label>
          </div>
        </div>

        <!-- Total/Invoiced column -->
        <div class="column">
          <label class="fs-14">
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(dataInvoice.amounts.total)
            }}
          </label>
        </div>
      </div>

      <div class="BaseInvoiceItem__top-container--mobile row">
        <!-- Debtor && Load Number -->
        <div class="column mr-16">
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <label class="fc-mid fs-14 uppercase width-100">
            {{ sanitizeAlgoliaNames(dataInvoice.debtor_selected_name) }}
          </label>
          <label class="fc-light fs-12 width-100">{{ dataInvoice.load_number }}</label>
        </div>

        <!-- Flag Icon -->
        <div class="column mr-16">
          <i
            v-if="dataInvoice.flagged && dataInvoice.last_flag_message"
            class="fa fa-flag fc-red fa--none fs-14"
          />
        </div>

        <!-- TOTAL/INVOICED -->
        <div class="column column--align-end">
          <label class="fs-14">
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(dataInvoice.amounts.total)
            }}
          </label>
        </div>
      </div>

      <!-- INVOICE MEMO (CLIENTS ONLY) -->
      <div
        v-if="userIsClient"
        class="row"
      >
        <label class="fc-light fs-14">{{ dataInvoice.memo }}</label>
      </div>
    </div>

    <!-- Invoice log && Footer -->
    <transition name="Transition__fade">
      <div
        v-show="bottomExpanded"
        class="column"
        data-cy="base-invoice-item-expanded"
      >
        <transition name="Transition__fade">
          <div class="BaseInvoiceItem__expanded-content column">
            <hr class="Divider">

            <invoice-updates
              :default-entries="3"
              :updates="dataInvoice.updates"
            />
          </div>
        </transition>

        <!-- Footer - hidden when editing an invoice for a seemless experience -->
        <transition
          mode="out-in"
          name="Transition__fade"
        >
          <div
            v-if="!toggles.editing"
            class="BaseInvoiceItem__footer row"
          >
            <transition
              mode="out-in"
              name="Transition__opacity-fade"
            >
              <div
                v-if="modalHidden && !additionalOptionsVisible"
                :class="['row row--align-center', { 'row--justify-between': mobile.isMobile }]"
                :key="'buttons'"
              >
                <!-- New Update -->
                <button
                  v-if="!userIsClient"
                  @click="toggles.update = true"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--newupdate fs-14 mr-30"
                  data-cy="base-invoice-item-add-update-btn"
                >
                  <i class="fa fa-add-circle fa-2" />
                  Add Update
                </button>
                <!-- View -->
                <button
                  @click="viewInvoice"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30"
                  :disabled="viewButtonDisabled"
                >
                  <i
                    v-if="loading"
                    class="fa fa-processing fa-spin fa-3"
                  />
                  <i
                    v-else
                    class="fa fa-view fa-3"
                  />
                  View
                </button>
                <!-- Edit -->
                <button
                  @click="editInvoice"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--edit fs-14 mr-30"
                  data-cy="base-invoice-item-edit"
                >
                  <i class="fa fa-edit fa-3" />
                  Edit
                </button>
                <!-- Share -->
                <button
                  @click="toggles.share = true"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--share fs-14 mr-30"
                >
                  <i class="fa fa-link fa-3" />
                  Share
                </button>
                <!-- MORE OPTIONS -->
                <button
                  @click="additionalOptionsVisible = true"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--options fs-14 ml-auto"
                >
                  <i class="fa fa-dots fs-14" />
                  More
                </button>
              </div>

              <!-- ADDITIONAL OPTIONS -->
              <div
                v-else-if="additionalOptionsVisible"
                :class="['row row--align-center', { 'row--justify-between': mobile.isMobile }]"
                :key="'additional-buttons'"
              >
                <!-- BACK/CLOSE -->
                <button
                  @click="additionalOptionsVisible = false"
                  class="mr-30"
                >
                  <i class="fa fa-back" />
                  Back
                </button>
                <!-- Regenerate Documents -->
                <button
                  v-show="!userIsClient"
                  @click="regenerateDocs"
                  :disabled="disableRegenerateButton"
                  class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--regenerate fs-14"
                >
                  <i class="fa fa-invoice-updates fa-3" />
                  Regenerate PDF
                </button>
              </div>

              <div
                v-else
                :key="'respectiveComponents'"
                class="width-100"
              >
                <!-- New Entry modal -->
                <item-update
                  v-show="toggles.update && !userIsClient"
                  @close="closeModal"
                  :id="dataInvoice.id"
                  :status="dataInvoice.status"
                />
                <!-- Share modal -->
                <item-share
                  v-if="toggles.share"
                  @close="closeModal"
                  :id="dataInvoice.debtor.id"
                  :invoice-id="dataInvoice.id"
                />
              </div>
            </transition>
          </div>

          <!-- Displayed when only when editing an invoice -->
          <base-invoice-create
            v-if="toggles.editing"
            @cancel="toggles.editing = false"
            @updated="toggles.editing = false"
            :invoice="editingInvoice"
            :user-is-client="userIsClient"
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import _ from 'lodash'
// Helpers
import {
  Invoice,
  InvoiceDocument,
  Debtor
} from '../utils/api'
import {
  formatPhoneNumber,
  sanitizeAlgoliaNames,
  transformRawInvoice
} from '../utils/helpers'
import FlagDisplayNames from '../shared/FlagDisplayNames.json'
// Components
import BaseInvoiceCreate from './base-invoice-create.vue'
import InvoiceUpdates from './invoice-updates.vue'
import ItemShare from './item-share.vue'
import ItemUpdate from './item-update.vue'

export default {
  name: 'BaseInvoiceItem',

  components: {
    BaseInvoiceCreate,
    InvoiceUpdates,
    ItemShare,
    ItemUpdate
  },

  props: {
    expandAll: {
      type: Boolean,
      default: false,
      required: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      additionalOptionsVisible: false,
      bottomExpanded: false,
      checkDocumentUrlInterval: null,
      dataInvoice: this.invoice,
      disableRegenerateButton: false,
      editingInvoice: null,
      flagDisplayName: FlagDisplayNames,
      loading: false,
      toggles: {
        editing: false,
        update: false,
        share: false
      },
      // By default, full_document_url is not present (not apart of search results)
      viewButtonDisabled: true,
    }
  },

  created () {
    // initialize the expansion state on load - needed to handle pagination correctly
    this.handleExpand()
  },

  beforeDestroy() {
    // it should stop searching when you leave the page
    if (this.checkDocumentUrlInterval) clearInterval(this.checkDocumentUrlInterval)
  },

  computed: {
    flagMessage () {
      return this.dataInvoice.last_flag_message.split(', ').length > 1
        ? `${this.dataInvoice.last_flag_message.split(', ').length} Flags`
        : this.flagDisplayName[this.dataInvoice.last_flag_message]
          || this.dataInvoice.last_flag_message
    },

    mobile () {
      return this.$store.getters.mobile
    },

    modalHidden () {
      return Object.keys(this.toggles).every(key => this.toggles[key] === false)
    },
  },

  watch: {
    // handle en masse toggling in invoices-declined (employee only)
    expandAll () {
      this.handleExpand()
    },

    invoice (newValue, oldValue) {
      // Debugging tool
      // this.CLog(
      //   'Full Document URL; New: %s, Old: %s',
      //   newValue.full_document_url,
      //   oldValue.full_document_url
      // )

      // Invoice needs updated if the prop invoice is different
      if (!_.isEqual(newValue, oldValue)) {
        // We don't want to look for a new full_document_url if an item-update was added or
        // a non-payment or payment was made
        if (newValue.updates.length > oldValue.updates.length) {
          if (!['declined', 'note'].includes(newValue.updates[0].update_type)) {
            // If the invoice full document url hasn't updated yet on the prop, keep looking
            if (newValue.full_document_url === oldValue.full_document_url) {
              this.viewButtonDisabled = true
              this.getInvoiceFullDocumentURL()
            }
          }
        }
        this.dataInvoice = newValue
      }
    },
  },

  methods: {
    closeModal() {
      this.modal = false
      Object.keys(this.toggles).forEach(key => this.$set(this.toggles, key, false))
    },

    async editInvoice () {
      if (this.toggles.editing) {
        this.toggles.editing = false
        return
      }
      try {
        this.editingInvoice = transformRawInvoice((await Invoice.get(this.dataInvoice.id)).data)
        this.toggles.editing = true
      }
      catch (error) {
        this.captureSentryEvent(
          'Declined Item Edit Get Invoice Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
      }
    },

    expand () {
      // updates and footer expand and collapse
      this.bottomExpanded = !this.bottomExpanded

      // Fetch the most recent invoice full_document URL if needed
      if (this.bottomExpanded === true) {
        if (!this.dataInvoice.full_document_url) this.getInvoiceFullDocumentURL()
        else this.viewButtonDisabled = false
      }
      this.closeModal()
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    sanitizeAlgoliaNames,

    // In case a # is not properly formatted in our BE
    formatPhoneNumber,

    getInvoiceFullDocumentURL () {
      this.loading = true
      const oldUrl = this.dataInvoice.full_document_url
      let iteration = 0
      try {
        this.checkDocumentUrlInterval = setInterval(async () => {
          const invoice = (await Invoice.get(this.dataInvoice.id)).data
          iteration += 1
          if (invoice.full_document_url !== oldUrl || iteration === 45) {
            this.$store.commit('REPLACE_EXISTING_INVOICE', invoice)
            this.$store.commit('REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE', invoice)
            this.loading = false
            this.viewButtonDisabled = false
            clearInterval(this.checkDocumentUrlInterval)
          }
        }, 4000)
      } catch (error) {
        this.captureSentryEvent(
          'Declined Item "GetInvoiceFullDocURL" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(`There was an issue in watching the invoice's PDF URL: ${error}`)
        clearInterval(this.checkDocumentUrlInterval)
      }
    },

    goToClientProfile () {
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'client',
          params: {
            id: this.dataInvoice.client.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'client',
          params: {
            id: this.dataInvoice.client.id.toString(),
          },
        })
      }
    },

    async goToDebtorProfile () {
      // Clients can't go to debtor profiles
      if (this.userIsClient) return
      // Cannot go to debtor profile of "Debtor Not Found"
      if (
        this.dataInvoice.debtor.id === await Debtor.getUnknownDebtorId()
        || this.dataInvoice.debtor.name.toLowerCase() === 'debtor not found'
      ) return
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'debtor',
          params: {
            id: this.dataInvoice.debtor.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'debtor',
          params: {
            id: this.dataInvoice.debtor.id.toString(),
          },
        })
      }
    },

    goToInvoiceDetail () {
      // Save search in localStorage for thoroughness and to cover all our use cases
      localStorage.setItem('search', window.location.search)
      localStorage.setItem('previousPage', 'declined')
      // Go to invoice details page
      this.$router.push({
        name: 'invoice-details',
        params: {
          id: this.dataInvoice.id.toString(),
        }
      })
    },

    handleExpand () {
      this.bottomExpanded = this.expandAll

      // If employee toggles expand/collapse, begin to get the full_document_url's
      if (this.bottomExpanded === true) {
        if (!this.dataInvoice.full_document_url) this.getInvoiceFullDocumentURL()
        else this.viewButtonDisabled = false
      }
    },

    async regenerateDocs () {
      this.progressStart()
      this.disableRegenerateButton = true
      try {
        await InvoiceDocument.regenerateDocs(this.invoice.id)
        this.progressFinish()
        this.disableRegenerateButton = false
        this.requestSuccess({ message: 'The invoice documents have been regenerated' })
      } catch (error) {
        this.captureSentryEvent(
          'Regenerate Invoice Documents Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.disableRegenerateButton = false
        this.requestFailure({ message: 'There was an error regenerating the invoice documents' })
      }
    },

    async viewInvoice () {
      let invoiceUrl = this.dataInvoice.full_document_url
      try {
        invoiceUrl = (await Invoice.get(this.dataInvoice.id)).data.full_document_url
      } catch (error) {
        this.captureSentryEvent(
          'View Invoice Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
      }
      window.open(invoiceUrl)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>
