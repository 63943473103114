<template>
  <div
    :class="['BaseInvoiceItem', {
      'BaseInvoiceItem--flagged': invoice.flagged && invoice.last_flag_message,
      'BaseInvoiceItem--mobile': mobile.isMobile,
    }]"
    :data-cy="`base-invoice-item-${invoice.load_number}`"
  >
    <!-- Invoice top-level information -->
    <div
      @click="expand"
      @keyup.enter="expand"
      :class="[
        'BaseInvoiceItem__top-container BaseInvoiceItem__top-container--paid',
        {
          'BaseInvoiceItem__top-container--paid-client': userIsClient,
          'BaseInvoiceItem__top-container--paid-employee': !userIsClient,
          'BaseInvoiceItem__top-container--unclickable': !mobile.isMobile && userIsClient
        }
      ]"
      tabindex="0"
    >
      <div class="BaseInvoiceItem__top-container--desktop row">
        <!-- ID + Load # -->
        <div class="column">
          <label
            class="fs-14 fw-med width-100"
            :data-cy="`base-invoice-item-load-${invoice.load_number}`"
            :title="invoice.load_number"
          >
            <!-- The maximum # of characters allowed in this space is 10 (with ...) -->
            {{
              invoice.load_number.length > 10
                ? `${invoice.load_number.slice(0, 10)}...`
                : invoice.load_number
            }}
          </label>
          <label
            v-if="!userIsClient"
            class="fc-light fs-12"
            data-cy="display-id"
            :title="invoice.id"
          >
            {{ invoice.display_id }}
          </label>
        </div>

        <!-- Flag column -->
        <div class="column">
          <label
            v-if="invoice.status_debtor_payment && invoice.status_debtor_payment !== 'fully_paid'"
            :class="['BaseInvoiceItem__flag-lbl fs-12 uppercase', {
              'bg-green': invoice.status_debtor_payment === 'overpay',
              'bg-red': invoice.status_debtor_payment === 'shortpay'
                || invoice.status_debtor_payment === 'nonpayment'
            }]"
            data-cy="base-invoice-item-flag"
          >
            {{ invoice.status_debtor_payment }}
          </label>
          <!-- FLAG ICON - Displayed at the smallest of desktop resolutions to give debtor more room -->
          <div class="BaseInvoiceItem__flag-icon column mr-16">
            <i
              v-if="invoice.status_debtor_payment && invoice.status_debtor_payment !== 'fully_paid'"
              :class="['fa fa-flag fa--none fs-18', {
                'fc-green': invoice.status_debtor_payment === 'overpay',
                'fc-red': invoice.status_debtor_payment === 'shortpay'
                  || invoice.status_debtor_payment === 'nonpayment'
              }]"
            />
          </div>
        </div>

        <!-- Debtor -->
        <div class="column">
          <!-- Having this column with a column allows the clickable range to only be on the
          actual debtor content (and outline for :focus) compared to being the entire debtor
          column width -->
          <div
            class="column column--width-auto"
            :title="
              invoice.debtor.broker
                ? `MC: ${invoice.debtor.broker.mc}`
                : 'MC: Not a broker'"
          >
            <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
            <label
              @click.stop="goToDebtorProfile"
              @keydown.enter.stop="goToDebtorProfile"
              :class="['fs-14 uppercase', {
                'clickable':
                  !userIsClient
                  && invoice.debtor.id !== 2
                  && invoice.debtor.name.toLowerCase() !== 'debtor not found'
              }]"
              :tabindex="!userIsClient ? 0 : null"
            >
              {{ sanitizeAlgoliaNames(invoice.debtor_selected_name) }}
            </label>
            <label class="fc-light fs-12">{{ formatPhoneNumber(invoice.debtor.phone) }}</label>
          </div>
        </div>

        <!-- Client -->
        <div
          v-if="!userIsClient"
          class="column"
        >
          <div
            class="column"
            :title="`MC: ${invoice.client.mc}`"
          >
            <label
              @click.stop="goToClientProfile"
              @keydown.enter.stop="goToClientProfile"
              class="clickable fs-14"
              tabindex="0"
            >
              {{ invoice.client.shortened_name }}
            </label>
            <label class="fc-light fs-12">{{ formatPhoneNumber(invoice.client.phone) }}</label>
          </div>
        </div>

        <!-- Days to Paid -->
        <div
          v-if="!userIsClient"
          class="column"
        >
          <label
            :class="['fs-14', {
              'fc-red': invoice.aging >= 45 && invoice.status_debtor_payment !== 'nonpayment'
            }]"
            data-cy="base-invoice-item-days-to-pay-label"
          >
            {{
              invoice.status_debtor_payment === 'nonpayment'
                ? 'NP'
                : invoice.aging
            }}
          </label>
        </div>

        <!-- Total/Invoiced -->
        <div class="column">
          <label class="fs-14">
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(invoice.amounts.total)
            }}
          </label>
          <label
            v-if="!userIsClient && (invoice.amounts.overpaid || invoice.amounts.shortpaid)"
            :class="['fs-14', {
              'fc-green': invoice.amounts.overpaid,
              'fc-red': invoice.amounts.shortpaid,
            }]"
          >
            {{
              invoice.amounts.overpaid
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(overpaidDiff)
                : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(shortpaidDiff)
            }}
          </label>
        </div>

        <!-- Paid -->
        <div
          v-if="userIsClient"
          class="column"
        >
          <label
            v-if="invoice.status_debtor_payment !== 'fully_paid'"
            class="fs-14"
          >
            {{
              invoice.amounts.overpaid
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(overpaidAmt)
                : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(shortpaidAmt)
            }}
          </label>
        </div>

        <!-- Diff -->
        <div
          v-if="userIsClient"
          class="column"
        >
          <label
            :class="['fs-14', {
              'fc-green': invoice.amounts.overpaid,
              'fc-red': invoice.amounts.shortpaid
            }]"
          >
            {{
              // If overpaid, ((total + overpaid) - total)
              // If shortpaid, (total - (total - shortpaid))
              invoice.amounts.overpaid
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(overpaidDiff)
                : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(shortpaidDiff)
            }}
          </label>
        </div>
      </div>

      <div class="BaseInvoiceItem__top-container--mobile row">
        <!-- DEBTOR && LOAD NUMBER -->
        <div class="column mr-16">
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <label class="fc-mid fs-14 uppercase width-100">
            {{ sanitizeAlgoliaNames(invoice.debtor_selected_name) }}
          </label>
          <label class="fc-light fs-12 width-100">{{ invoice.load_number }}</label>
        </div>

        <!-- FLAG ICON -->
        <div class="column mr-16">
          <i
            v-if="invoice.status_debtor_payment && invoice.status_debtor_payment !== 'fully_paid'"
            :class="['fa fa-flag fa--none fs-14', {
              'fc-green': invoice.status_debtor_payment === 'overpay',
              'fc-red': invoice.status_debtor_payment === 'shortpay'
                || invoice.status_debtor_payment === 'nonpayment'
            }]"
          />
        </div>

        <!-- Paid & Diff -->
        <div class="column column--align-end">
          <label
            v-if="invoice.status_debtor_payment !== 'fully_paid'"
            class="fs-14"
          >
            {{
              invoice.amounts.overpaid
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(overpaidAmt)
                : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(shortpaidAmt)
            }}
          </label>

          <!-- Diff -->
          <label
            :class="['fs-14', {
              'fc-green': invoice.amounts.overpaid,
              'fc-red': invoice.amounts.shortpaid
            }]"
          >
            {{
              // If overpaid, ((total + overpaid) - total)
              // If shortpaid, (total - (total - shortpaid))
              invoice.amounts.overpaid
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(overpaidDiff)
                : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(shortpaidDiff)
            }}
          </label>
        </div>
      </div>

      <!-- INVOICE MEMO (CLIENTS ONLY) -->
      <div
        v-if="userIsClient"
        class="row"
      >
        <label class="fc-light fs-14">{{ invoice.memo }}</label>
      </div>
    </div>

    <!-- Invoice log && Footer -->
    <transition name="Transition__fade">
      <div
        v-show="bottomExpanded"
        class="column"
        data-cy="base-invoice-item-expanded"
      >
        <transition name="Transition__fade">
          <div class="BaseInvoiceItem__expanded-content column">
            <hr class="Divider">

            <invoice-updates
              :default-entries="3"
              :updates="invoice.updates"
            />
          </div>
        </transition>

        <!-- Footer -->
        <!-- Payment Details takes up the full-width (design choice), so we need to modify -->
        <div
          :class="['BaseInvoiceItem__footer row', {
            'bg': toggles.paymentDetails && !userIsClient,
          }]"
        >
          <transition
            mode="out-in"
            name="Transition__opacity-fade"
          >
            <div
              v-if="modalHidden && !additionalOptionsVisible"
              :class="['row row--align-center', { 'row--justify-between': mobile.isMobile }]"
              :key="'buttons'"
            >
              <!-- New Update -->
              <button
                v-if="!userIsClient"
                @click="toggles.update = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--newupdate fs-14 mr-30"
                data-cy="base-invoice-item-add-update-btn"
              >
                <i class="fa fa-add-circle" />
                Add Update
              </button>
              <!-- Post Payment -->
              <button
                v-if="!userIsClient"
                @click="toggles.payment = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--postpayment fs-14 mr-30"
                data-cy="base-invoice-item-post-payment-btn"
              >
                <i class="fa fa-payment" />
                Post Payment
              </button>
              <!-- Share -->
              <button
                @click="toggles.share = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--share fs-14 mr-30"
              >
                <i class="fa fa-link" />
                Share
              </button>
              <!-- View -->
              <button
                @click="viewInvoice"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30"
              >
                <i class="fa fa-view fa-3" />
                View
              </button>
              <button
                v-if="!userIsClient"
                @click="toggles.paymentDetails = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30"
                data-cy="base-invoice-item-payment-details-btn"
              >
                <i class="fa fa-ellipsis" />
                Payment Details
              </button>
              <!-- MORE OPTIONS -->
              <button
                @click="additionalOptionsVisible = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--options fs-14 ml-auto"
              >
                <i class="fa fa-dots fs-14" />
                More
              </button>
            </div>

            <!-- ADDITIONAL OPTIONS -->
            <div
              v-else-if="additionalOptionsVisible"
              :class="['row row--align-center', { 'row--justify-between': mobile.isMobile }]"
              :key="'additional-buttons'"
            >
              <!-- BACK/CLOSE -->
              <button
                @click="additionalOptionsVisible = false"
                class="fs-14 mr-30"
              >
                <i class="fa fa-back" />
                Back
              </button>
              <!-- Regenerate Documents -->
              <button
                v-show="!userIsClient"
                @click="regenerateDocs"
                :disabled="disableRegenerateButton"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--regenerate fs-14 mr-30"
              >
                <i class="fa fa-invoice-updates fa-3" />
                Regenerate PDF
              </button>
              <!-- Undo -->
              <button
                v-show="!userIsClient && invoice.status_client_payment === 'not_paid'"
                @click="additionalOptionsVisible = false; toggles.undo = true"
                class="BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--undo fs-14"
              >
                <i class="fa fa-repeat" />
                Undo
              </button>
            </div>

            <!-- Used to display inputs for each footer option -->
            <div
              v-else
              :key="'respectiveComponents'"
              class="width-100"
            >
              <!-- New Entry modal -->
              <item-update
                v-if="toggles.update && !userIsClient"
                @close="closeModal"
                :id="invoice.id"
                :status="invoice.status"
              />
              <!-- Record Payment modal -->
              <item-payment
                v-if="toggles.payment && !userIsClient"
                @close="closeModal"
                :id="invoice.id"
                :invoice-status="'paid'"
                :invoice-total="invoice.amounts.total"
              />
              <!-- Share modal -->
              <item-share
                v-if="toggles.share"
                @close="closeModal"
                :id="invoice.debtor.id"
                :invoice-id="invoice.id"
              />
              <!-- Undo modal -->
              <base-item-undo
                v-if="toggles.undo && !userIsClient"
                @close="closeModal"
                :id="invoice.id"
                :is-buyout="invoice.is_buyout"
                :status-back-to="'approved'"
              />
              <item-payment-details
                v-if="toggles.paymentDetails && !userIsClient"
                @close="closeModal"
                :invoice-id="invoice.id"
              />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import FlagDisplayNames from '../shared/FlagDisplayNames.json'
import { InvoiceDocument } from '../utils/api'
import {
  formatPhoneNumber,
  sanitizeAlgoliaNames
} from '../utils/helpers'
// Components
import BaseItemUndo from './base-item-undo.vue'
import InvoiceUpdates from './invoice-updates.vue'
import ItemPayment from './item-payment.vue'
import ItemPaymentDetails from './item-payment-details.vue'
import ItemShare from './item-share.vue'
import ItemUpdate from './item-update.vue'

export default {
  name: 'BaseInvoiceItem',

  components: {
    BaseItemUndo,
    InvoiceUpdates,
    ItemPayment,
    ItemPaymentDetails,
    ItemShare,
    ItemUpdate,
  },

  props: {
    expandAll: {
      type: Boolean,
      default: false,
      required: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      additionalOptionsVisible: false,
      bottomExpanded: false,
      disableRegenerateButton: false,
      flagDisplayName: FlagDisplayNames,
      toggles: {
        payment: false,
        paymentDetails: false,
        share: false,
        undo: false,
        update: false,
      },
    }
  },

  created () {
    // initialize the expansion state on load - needed to handle pagination correctly
    this.handleExpand()
  },

  computed: {
    flagMessage () {
      return this.invoice.last_flag_message.split(', ').length > 1
        ? `${this.invoice.last_flag_message.split(', ').length} Flags`
        : this.flagDisplayName[this.invoice.last_flag_message]
          || this.invoice.last_flag_message
    },

    mobile () {
      return this.$store.getters.mobile
    },

    modalHidden () {
      return Object.keys(this.toggles).every(key => this.toggles[key] === false)
    },

    overpaidAmt () {
      return Number(this.invoice.amounts.total) + Number(this.invoice.amounts.overpaid)
    },

    overpaidDiff () {
      return this.overpaidAmt - Number(this.invoice.amounts.total)
    },

    shortpaidAmt () {
      return Number(this.invoice.amounts.total) - Number(this.invoice.amounts.shortpaid)
    },

    shortpaidDiff () {
      return Number(this.invoice.amounts.total) - this.shortpaidAmt
    },
  },

  watch: {
    // handle en masse toggling in invoices-paid (employee only)
    expandAll () {
      this.handleExpand()
    },
  },

  methods: {
    closeModal() {
      Object.keys(this.toggles).forEach(key => this.$set(this.toggles, key, false))
    },

    expand () {
      // updates and footer expand and collapse
      this.bottomExpanded = !this.bottomExpanded
      this.closeModal()
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    sanitizeAlgoliaNames,

    // In case a # is not properly formatted in our BE
    formatPhoneNumber,

    goToClientProfile () {
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'client',
          params: {
            id: this.invoice.client.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'client',
          params: {
            id: this.invoice.client.id.toString(),
          },
        })
      }
    },

    goToDebtorProfile () {
      if (this.userIsClient) return
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'debtor',
          params: {
            id: this.invoice.debtor.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'debtor',
          params: {
            id: this.invoice.debtor.id.toString(),
          },
        })
      }
    },

    handleExpand () {
      this.bottomExpanded = this.expandAll
    },

    async regenerateDocs () {
      this.progressStart()
      this.disableRegenerateButton = true
      try {
        await InvoiceDocument.regenerateDocs(this.invoice.id)
        this.progressFinish()
        this.disableRegenerateButton = false
        this.requestSuccess({ message: 'The invoice documents have been regenerated' })
      } catch (error) {
        this.captureSentryEvent(
          'Regenerate Invoice Documents Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.disableRegenerateButton = false
        this.requestFailure({ message: 'There was an error regenerating the invoice documents' })
      }
    },


    viewInvoice () {
      // Paid invoice search results always have the full_document_url
      window.open(this.invoice.full_document_url)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
.BaseInvoiceItem

  .fa-ellipsis
    font-size: rem(4px)
</style>
