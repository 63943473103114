var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInvoiceTableRows" },
    [
      _vm.status === "approved"
        ? _vm._l(_vm.sortedInvoices, function(invoice) {
            return _c("base-approved-item", {
              key: invoice.id,
              attrs: {
                "expand-all": _vm.expandAll,
                invoice: invoice,
                "user-is-client": _vm.userIsClient
              }
            })
          })
        : _vm._e(),
      _vm.status === "declined"
        ? _vm._l(_vm.sortedInvoices, function(invoice) {
            return _c("base-declined-item", {
              key: invoice.id,
              attrs: {
                "expand-all": _vm.expandAll,
                invoice: invoice,
                "user-is-client": _vm.userIsClient
              }
            })
          })
        : _vm._e(),
      _vm.status === "paid"
        ? _vm._l(_vm.sortedInvoices, function(invoice) {
            return _c("base-paid-item", {
              key: invoice.id,
              attrs: {
                "expand-all": _vm.expandAll,
                invoice: invoice,
                "user-is-client": _vm.userIsClient
              }
            })
          })
        : _vm._e(),
      _vm.status === "pending"
        ? _vm._l(_vm.sortedInvoices, function(invoice) {
            return _c("base-pending-item", {
              key: invoice.id,
              attrs: {
                "expand-all": _vm.expandAll,
                invoice: invoice,
                "user-is-client": _vm.userIsClient
              }
            })
          })
        : _vm._e(),
      !_vm.sortedInvoices.length && _vm.mobile.isMobile
        ? [_c("label", { staticClass: "ml-16" }, [_vm._v("No invoices")])]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }