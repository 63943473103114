var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ItemPaymentDetails row" }, [
    _c(
      "div",
      {
        staticClass:
          "ItemPaymentDetails__close-icon-wrapper column column--width-auto"
      },
      [
        _c("i", {
          staticClass:
            "fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon",
          attrs: { tabindex: "0" },
          on: {
            click: _vm.closeModal,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.closeModal($event)
            }
          }
        })
      ]
    ),
    _c(
      "div",
      { staticClass: "ItemPaymentDetails__content column" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "label",
            {
              staticClass:
                "Table__header-lbl Table__header-lbl--ItemPaymentDetails"
            },
            [_vm._v("POSTED DATE")]
          ),
          _c(
            "label",
            {
              class: [
                "Table__header-lbl Table__header-lbl--ItemPaymentDetails",
                {
                  "ItemPaymentDetails__expanded-header": _vm.paymentUpdate
                }
              ]
            },
            [_vm._v("BATCH DATE")]
          ),
          _c(
            "label",
            {
              class: [
                "Table__header-lbl Table__header-lbl--ItemPaymentDetails",
                {
                  "ItemPaymentDetails__expanded-header": _vm.paymentUpdate
                }
              ]
            },
            [_vm._v("AMOUNT")]
          ),
          _c(
            "label",
            {
              class: [
                "Table__header-lbl Table__header-lbl--ItemPaymentDetails",
                {
                  "ItemPaymentDetails__expanded-header": _vm.paymentUpdate
                }
              ]
            },
            [_vm._v("CHECK #")]
          ),
          _c("label", {
            staticClass:
              "Table__header-lbl Table__header-lbl--ItemPaymentDetails"
          })
        ]),
        _c(
          "transition-group",
          {
            staticClass: "column",
            attrs: { name: "Transition__opacity-fade", tag: "div" }
          },
          _vm._l(_vm.debtorPayments, function(payment, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "Table__item-wrapper Table__item-wrapper--ItemPaymentDetails row row--align-center",
                attrs: { "data-cy": "item-payment-details-" + index }
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "column",
                      {
                        "ItemPaymentDetails__item-border": !payment.editing
                      }
                    ]
                  },
                  [
                    _c("label", { staticClass: "fs-14 uppercase" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("datetime")(payment.created_at, "MM/DD/YYYY")
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    class: [
                      "column",
                      {
                        "ItemPaymentDetails__item-border": !payment.editing
                      }
                    ]
                  },
                  [
                    !payment.editing
                      ? _c("label", { staticClass: "fs-14 uppercase" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("datetime")(
                                  payment.batch_date,
                                  "MM/DD/YYYY"
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      : _c("base-date-picker", {
                          staticClass: "ItemPaymentDetails__input",
                          attrs: {
                            "data-cy": "item-payment-details-batch-date-input",
                            placeholder: "Batch Date"
                          },
                          model: {
                            value: _vm.paymentUpdate.batch_date,
                            callback: function($$v) {
                              _vm.$set(_vm.paymentUpdate, "batch_date", $$v)
                            },
                            expression: "paymentUpdate.batch_date"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    class: [
                      "column",
                      {
                        "ItemPaymentDetails__item-border": !payment.editing
                      }
                    ]
                  },
                  [
                    _c("label", { staticClass: "fs-14" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(payment.amount / 100)
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    class: [
                      "column",
                      {
                        "ItemPaymentDetails__item-border": !payment.editing
                      }
                    ]
                  },
                  [
                    !payment.editing
                      ? _c("label", { staticClass: "fs-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                payment.transaction_type === "ach"
                                  ? "ACH"
                                  : payment.check_number
                              ) +
                              "\n          "
                          )
                        ])
                      : _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("base-toggle", {
                              staticClass: "mr-10",
                              attrs: {
                                "active-option": _vm.paymentMethod,
                                toggles: _vm.paymentMethodToggles
                              },
                              on: { toggle: _vm.updateTransactionType }
                            }),
                            _vm.paymentMethod === "check"
                              ? _c("base-input", {
                                  staticClass: "ItemPaymentDetails__input",
                                  model: {
                                    value: _vm.paymentUpdate.check_number,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.paymentUpdate,
                                        "check_number",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "paymentUpdate.check_number"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                  ]
                ),
                _c("div", { staticClass: "column" }, [
                  !payment.editing
                    ? _c("div", { staticClass: "row row--align-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "ItemPaymentDetails__action-btn",
                            attrs: {
                              "data-cy": "item-payment-details-edit-btn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openEditPayment(payment)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-edit fa--none fa-15 fc-light"
                            })
                          ]
                        ),
                        payment.allowDelete
                          ? _c(
                              "button",
                              {
                                staticClass: "ItemPaymentDetails__action-btn",
                                attrs: {
                                  "data-cy": "item-payment-details-delete-btn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeletePayment(payment)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-trash-can fa--none fa-15 fc-light"
                                })
                              ]
                            )
                          : _vm._e()
                      ])
                    : _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-6",
                              attrs: {
                                "data-cy": "item-payment-details-save-btn",
                                disabled: _vm.saveDisabled
                              },
                              on: {
                                click: _vm.saveUpdate,
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.saveUpdate($event)
                                }
                              }
                            },
                            [_vm._v("\n              Save\n            ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white",
                              on: {
                                click: _vm.cancelUpdate,
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.cancelUpdate($event)
                                }
                              }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          )
                        ],
                        1
                      )
                ])
              ]
            )
          }),
          0
        ),
        _c(
          "transition",
          { attrs: { name: "Transition__fade" } },
          [
            _vm.deleting
              ? _c("base-item-delete", {
                  attrs: {
                    "confirm-text": "Yes, delete payment",
                    "delete-text":
                      "Are you sure you would like to delete payment for " +
                      Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD"
                      }).format(_vm.activePayment.amount / 100) +
                      "?"
                  },
                  on: { cancel: _vm.cancelDelete, delete: _vm.deletePayment }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }