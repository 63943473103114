var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "BaseInvoiceItem",
        {
          "BaseInvoiceItem--flagged":
            _vm.dataInvoice.flagged && _vm.dataInvoice.last_flag_message,
          "BaseInvoiceItem--mobile": _vm.mobile.isMobile
        }
      ],
      attrs: { "data-cy": "base-invoice-item-" + _vm.dataInvoice.load_number }
    },
    [
      _c(
        "div",
        {
          class: [
            "BaseInvoiceItem__top-container BaseInvoiceItem__top-container--pending",
            {
              "BaseInvoiceItem__top-container--unclickable":
                !_vm.mobile.isMobile && _vm.userIsClient
            }
          ],
          attrs: { tabindex: "0" },
          on: {
            click: _vm.expand,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.expand($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "BaseInvoiceItem__top-container--desktop row" },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    class: [
                      "fs-14 fw-med width-100",
                      { clickable: !this.userIsClient }
                    ],
                    attrs: {
                      "data-cy": "base-invoice-item-load-number-lbl",
                      tabindex: !_vm.userIsClient ? 0 : null,
                      title: _vm.dataInvoice.load_number
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.goToInvoiceDetail($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.dataInvoice.load_number.length > 10
                            ? _vm.dataInvoice.load_number.slice(0, 10) + "..."
                            : _vm.dataInvoice.load_number
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _vm.flagDisplayStates.notActive
                  ? _c(
                      "label",
                      {
                        staticClass:
                          "BaseInvoiceItem__flag-lbl fs-12 bg-yellow uppercase",
                        attrs: { id: "Testing__InactiveItemFlag" }
                      },
                      [_vm._v("\n          Not Active\n        ")]
                    )
                  : _vm._e(),
                _vm.flagDisplayStates.flags
                  ? _c(
                      "label",
                      {
                        staticClass:
                          "BaseInvoiceItem__flag-lbl fs-12 bg-red uppercase",
                        attrs: { "data-cy": "base-invoice-item-flag" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.flagMessage) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "BaseInvoiceItem__flag-icon column mr-16" },
                  [
                    _vm.flagDisplayStates.notActive
                      ? _c("i", {
                          staticClass: "fa fa-flag fc-yellow fa--none fs-18"
                        })
                      : _vm.flagDisplayStates.flags
                      ? _c("i", {
                          staticClass: "fa fa-flag fc-red fa--none fs-18"
                        })
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  {
                    staticClass: "column column--width-auto",
                    attrs: {
                      title: _vm.dataInvoice.debtor.broker
                        ? "MC: " + _vm.dataInvoice.debtor.broker.mc
                        : "MC: Not a broker"
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 uppercase",
                          {
                            clickable:
                              !_vm.userIsClient &&
                              _vm.dataInvoice.debtor.name.toLowerCase() !==
                                "debtor not found"
                          }
                        ],
                        attrs: { tabindex: !_vm.userIsClient ? 0 : null },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goToDebtorProfile($event)
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            return _vm.goToDebtorProfile($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.filterAlgoliaDebtorName(
                                _vm.dataInvoice.debtor_selected_name
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _c("label", { staticClass: "fc-light fs-12" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPhoneNumber(_vm.dataInvoice.debtor.phone)
                        )
                      )
                    ])
                  ]
                )
              ]),
              !_vm.userIsClient
                ? _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        attrs: { title: "MC: " + _vm.dataInvoice.client.mc }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "clickable fs-14",
                            attrs: { tabindex: "0" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToClientProfile($event)
                              },
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.goToClientProfile($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dataInvoice.client.shortened_name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("label", { staticClass: "fc-light fs-12" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatPhoneNumber(
                                _vm.dataInvoice.client.phone
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.dataInvoice.amounts.total)
                      ) +
                      "\n        "
                  )
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "BaseInvoiceItem__top-container--mobile row" },
            [
              _c("div", { staticClass: "column mr-16" }, [
                _c(
                  "label",
                  { staticClass: "fc-mid fs-14 uppercase width-100" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.filterAlgoliaDebtorName(
                            _vm.dataInvoice.debtor_selected_name
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "fc-light fs-12 width-100",
                    attrs: { "data-cy": "base-invoice-item-load-number-lbl" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.dataInvoice.load_number) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column mr-16" }, [
                _vm.dataInvoice.flagged && _vm.dataInvoice.last_flag_message
                  ? _c("i", { staticClass: "fa fa-flag fc-red fa--none fs-14" })
                  : _vm._e()
              ]),
              _c("div", { staticClass: "column column--align-end" }, [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.dataInvoice.amounts.total)
                      ) +
                      "\n        "
                  )
                ])
              ])
            ]
          ),
          _vm.userIsClient
            ? _c("div", { staticClass: "row" }, [
                _c("label", { staticClass: "fc-light fs-14" }, [
                  _vm._v(_vm._s(_vm.dataInvoice.memo))
                ])
              ])
            : _vm._e()
        ]
      ),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bottomExpanded,
                expression: "bottomExpanded"
              }
            ],
            staticClass: "column",
            attrs: { "data-cy": "base-invoice-item-expanded" }
          },
          [
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              _c(
                "div",
                { staticClass: "BaseInvoiceItem__expanded-content column" },
                [
                  _c("hr", { staticClass: "Divider" }),
                  _c("invoice-updates", {
                    attrs: {
                      "default-entries": 3,
                      updates: _vm.dataInvoice.updates
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "transition",
              { attrs: { mode: "out-in", name: "Transition__fade" } },
              [
                !_vm.toggles.editing && !_vm.toggles.deleting
                  ? _c(
                      "div",
                      { staticClass: "BaseInvoiceItem__footer row" },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              mode: "out-in",
                              name: "Transition__opacity-fade"
                            }
                          },
                          [
                            _vm.modalHidden && !_vm.additionalOptionsVisible
                              ? _c(
                                  "div",
                                  {
                                    key: "buttons",
                                    class: [
                                      "row row--align-center",
                                      {
                                        "row--justify-between":
                                          _vm.mobile.isMobile
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.userIsClient,
                                            expression: "!userIsClient"
                                          }
                                        ],
                                        staticClass:
                                          " BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--newupdate fs-14 mr-30",
                                        attrs: {
                                          "data-cy":
                                            "base-invoice-item-add-update-btn"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.toggles.update = true
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-add-circle fa-2"
                                        }),
                                        _vm._v(
                                          "\n                Add Update\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          " BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30",
                                        attrs: {
                                          "data-cy":
                                            "base-invoice-item-view-btn",
                                          disabled: _vm.viewButtonDisabled
                                        },
                                        on: { click: _vm.viewInvoice }
                                      },
                                      [
                                        _vm.loading
                                          ? _c("i", {
                                              staticClass:
                                                "fa fa-processing fa-spin fa-3"
                                            })
                                          : _c("i", {
                                              staticClass: "fa fa-view fa-3"
                                            }),
                                        _vm._v(
                                          "\n                View\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          " BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--edit fs-14 mr-30",
                                        attrs: {
                                          "data-cy":
                                            "base-invoice-item-edit-btn"
                                        },
                                        on: { click: _vm.editInvoice }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-edit fa-3"
                                        }),
                                        _vm._v(
                                          "\n                Edit\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--options fs-14 ml-auto",
                                        attrs: {
                                          "data-cy":
                                            "base-invoice-item-more-btn"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.additionalOptionsVisible = true
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-dots fs-14"
                                        }),
                                        _vm._v(
                                          "\n                More\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm.additionalOptionsVisible
                              ? _c(
                                  "div",
                                  {
                                    key: "additional-buttons",
                                    class: [
                                      "row row--align-center",
                                      {
                                        "row--justify-between":
                                          _vm.mobile.isMobile
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "mr-30",
                                        attrs: {
                                          "data-cy":
                                            "base-invoice-item-reverse-more-btn"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.additionalOptionsVisible = false
                                          }
                                        }
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-back" }),
                                        _vm._v(
                                          "\n                Back\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.userIsClient,
                                            expression: "!userIsClient"
                                          }
                                        ],
                                        staticClass:
                                          "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--regenerate fs-14 mr-30",
                                        attrs: {
                                          disabled: _vm.disableRegenerateButton
                                        },
                                        on: { click: _vm.regenerateDocs }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-invoice-updates fa-3"
                                        }),
                                        _vm._v(
                                          "\n                Regenerate PDF\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--delete fs-14",
                                        attrs: {
                                          disabled: _vm.disableDeleteBtn,
                                          "data-cy":
                                            "base-invoice-item-delete-btn"
                                        },
                                        on: { click: _vm.openDelete }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-trash-can fa-3"
                                        }),
                                        _vm._v(
                                          "\n                Delete\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    key: "respectiveComponents",
                                    staticClass: "width-100"
                                  },
                                  [
                                    _c("item-update", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.toggles.update &&
                                            !_vm.userIsClient,
                                          expression:
                                            "toggles.update && !userIsClient"
                                        }
                                      ],
                                      attrs: {
                                        id: _vm.dataInvoice.id,
                                        status: _vm.dataInvoice.status
                                      },
                                      on: { close: _vm.closeModal }
                                    })
                                  ],
                                  1
                                )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.toggles.editing
                  ? _c("base-invoice-create", {
                      attrs: {
                        invoice: _vm.editingInvoice,
                        "user-is-client": _vm.userIsClient
                      },
                      on: {
                        cancel: function($event) {
                          _vm.toggles.editing = false
                        },
                        updated: _vm.updatedCallback
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "Transition__fade" } },
              [
                _vm.toggles.deleting
                  ? _c("base-item-delete", {
                      attrs: { invoice: _vm.dataInvoice },
                      on: {
                        cancel: function($event) {
                          _vm.toggles.deleting = false
                        },
                        delete: _vm.deleteInvoice
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }