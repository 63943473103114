<template>
  <div class="BaseInvoiceItem__FooterOption row">
    <div class="BaseInvoiceItem__FooterOption__x-icon-wrapper">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="
          fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon
        "
        tabindex="0"
      />
    </div>

    <transition name="Transition__opacity-fade">
      <div
        v-if="debtorRequestFinished"
        class="BaseInvoiceItem__FooterOption__right row row--align-center row--wrap"
      >
        <label class="fs-14">Who do you want to send this invoice to?</label>
        <base-input
          v-model.trim="emails.value"
          class="BaseInvoiceItem__FooterOption__note"
          :instructions="instructions.INVOICE_DELIVERY_EMAILS_INSTRUCTION"
          :placeholder="'email(s)'"
          :valid="emails.valid"
        />
        <base-button
          @click="shareInvoice"
          :disabled="(!emails.value && !debtorEmail) || buttonDisabled"
          class="bg-blue fc-white"
        >
          Send
        </base-button>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Debtor,
  Email
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'ItemShare',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    invoiceId: {
      type: String,
      required: true,
    },
  },

  async created () {
    if (!this.id) {
      return
    }
    try {
      const debtor = (await Debtor.get(this.id)).data
      this.$set(this.emails, 'value', debtor.invoice_delivery_emails ? debtor.invoice_delivery_emails.join(',') : '')
      this.debtorRequestFinished = true
    }
    catch (error) {
      this.captureSentryEvent(
        'Item Share "Get Debtor" Error',
        {
          config: error.config,
          data: this.$data,
          details: error,
          props: this.$props,
          response: error.response,
        }
      )
      this.CError(error.response)
      // It's still "done", but failed; yet, component can still be displayed
      this.debtorRequestFinished = true
      this.requestFailure({
        message: 'We had an issue fetching the debtor\'s information'
      })
    }
  },

  data () {
    return {
      buttonDisabled: false,
      debtorRequestFinished: false,
      emails: {
        valid: true,
        value: '',
      },
    }
  },

  methods: {
    closeModal () {
      this.$emit('close')
    },

    async shareInvoice () {
      if (!this.isValidEmails(this.emails.value)) {
        this.$set(this.emails, 'valid', false)
        return
      }
      this.$set(this.emails, 'valid', true)

      this.buttonDisabled = true
      this.progressStart()
      try {
        await Email.shareInvoice({
          email: this.emails.value,
          invoice_id: this.invoiceId
        })
        this.buttonDisabled = false
        this.requestSuccess({ message: 'Email sent' })
        this.closeModal()
      }
      catch (error) {
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1855
        let message = (error.response && error.response.data && error.response.data.message)
          ? error.response.data.message
          : 'There was an issue sending the email'

        if (
          error.response && error.response.data
          && error.response.data.stack && error.response.data.stack.includes('10MB')
        ) {
          message = 'Could not attach file greater than 10MB. Please send manually'
        }

        this.captureSentryEvent(
          'Item Share "Share" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonDisabled = false
        this.requestFailure({ message })
      }
    },
  }
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>