<template>
  <div class="BaseInvoiceItem__FooterOption row">
    <div class="BaseInvoiceItem__FooterOption__x-icon-wrapper">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon"
        data-cy="base-invoice-item-footer-option-x-btn"
        tabindex="0"
      />
    </div>

    <div class="BaseInvoiceItem__FooterOption__right row row--wrap">
      <v-select
        v-model="update_status"
        :clearable="false"
        data-cy="item-update-type-select"
        :options="statusOptions"
        :placeholder="'Click to update status'"
      />

      <template v-if="update_status === 'debtor claim'">
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          data-cy="item-update-notes-input"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'filed on bond'">
        <template v-if="status === 'approved'">
          <base-input
            v-model.trim="inputs.notes"
            class="BaseInvoiceItem__FooterOption__note"
            data-cy="item-update-notes-input"
            :placeholder="'Any notes?'"
          />
        </template>

        <template v-if="status === 'paid'">
          <base-input
            v-model.trim="inputs.talked_to"
            data-cy="item-update-talked-to-input"
            :placeholder="'Who did you talk to?'"
          />
          <base-toggle
            @toggle="inputs.talked_to_contact_method = $event"
            data-cy="item-update-talked-to-toggle"
            :emits-default-on-load="false"
            :toggles="talkedToToggle"
          />
          <base-input
            v-model.trim="inputs.notes"
            class="BaseInvoiceItem__FooterOption__note--full-width"
            data-cy="item-update-notes-input"
            :placeholder="'Any notes?'"
          />
        </template>
      </template>

      <template v-if="update_status === 'missing paperwork'">
        <base-input
          v-model.trim="inputs.missing"
          data-cy="item-update-missing-input"
          :placeholder="'Missing'"
        />
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          :active-option="inputs.talked_to_contact_method"
          data-cy="item-update-talked-to-toggle"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'note'">
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'originals required'">
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'other'">
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'paid to client'">
        <base-toggle
          @toggle="inputs.paymentMethod = $event"
          :active-option="inputs.paymentMethod"
          data-cy="item-update-payment-method-toggle"
          :toggles="paymentMethodToggles"
        />
        <base-input
          v-model.trim="inputs.check.value"
          data-cy="item-update-check-number-input"
          :instructions="instructions.CHECK_NUMBER_INSTRUCTION"
          :placeholder="'Check #'"
          :valid="inputs.check.valid"
        />
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          :active-option="inputs.talked_to_contact_method"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'payment'">
        <base-date-picker
          v-model="inputs.issue_date"
          name="issue-date"
          data-cy="item-update-payment-issue-date"
          :placeholder="'Payment Date'"
        />
        <base-input
          v-model.trim="inputs.check.value"
          data-cy="item-update-check-number-input"
          :instructions="instructions.CHECK_NUMBER_INSTRUCTION"
          :placeholder="'Check #'"
          :valid="inputs.check.valid"
        />
        <base-toggle
          @toggle="inputs.paymentMethod = $event"
          :active-option="inputs.paymentMethod"
          data-cy="item-update-payment-method-toggle"
          :toggles="paymentMethodToggles"
        />
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'processing'">
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'shortpay reason'">
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <template v-if="update_status === 'unreadable'">
        <base-input
          v-model.trim="inputs.talked_to"
          data-cy="item-update-talked-to-input"
          :placeholder="'Who did you talk to?'"
        />
        <base-toggle
          @toggle="inputs.talked_to_contact_method = $event"
          data-cy="item-update-talked-to-toggle"
          :emits-default-on-load="false"
          :toggles="talkedToToggle"
        />
        <base-input
          v-model.trim="inputs.notes"
          class="BaseInvoiceItem__FooterOption__note--full-width"
          data-cy="item-update-notes-input"
          :placeholder="'Any notes?'"
        />
      </template>

      <base-button
        @click="addLogUpdate"
        data-cy="item-update-submit-btn"
        :disabled="!requiredFields || buttonDisabled"
        class="bg-blue fc-white"
      >
        Add Update
      </base-button>
    </div>
  </div>
</template>

<script>
// Packages
import validator from 'validator'
// Helpers
import {
  Invoice,
  InvoiceUpdate
} from '../utils/api'
// JSON import
import Statuses from '../shared/InvoiceUpdateStatus.json'
// Component imports
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseInput from './base-input.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'ItemUpdate',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
    BaseToggle,
  },

  mixins: [ValidationMixin],

  props: {
    id: { // invoice id
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },

  created () {
    this.statusOptions = Statuses[this.status].map(status => status.status)
  },

  data () {
    return {
      buttonDisabled: false,
      inputs: {
        check: {
          valid: true,
          value: '',
        },
        issue_date: null,
        missing: '',
        notes: '',
        paymentMethod: 'check',
        talked_to: '',
        talked_to_contact_method: 'phone',
      },
      paymentMethodToggles: ['Check', 'ACH'],
      statusOptions: null,
      talkedToToggle: ['phone', 'email'],
      update_status: '',
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    'inputs.check.value': function (newValue) {
      if (newValue.length) {
        this.$set(this.inputs, 'paymentMethod', 'check')
      }
    },

    // eslint-disable-next-line func-names
    update_status () {
      // Prevent errors such as:
      // https://bobtail.atlassian.net/browse/BD-124?focusedCommentId=10181
      this.inputs = this.$options.data().inputs
    },
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },

    requiredFields () {
      const {
        issue_date, missing, notes, talked_to
      } = this.inputs

      // [] required
      if ([
        'did not pick up',
        'originals required',
        'processing'
      ].includes(this.update_status)) {
        return true
      }

      // [notes] required
      if (['note', 'other'].includes(this.update_status)) {
        const arr = [notes]
        return arr.every(item => item.length > 0)
      }

      // [talked_to] required
      if (['unreadable', 'paid to client'].includes(this.update_status)) {
        const arr = [talked_to]
        return arr.every(item => item.length > 0)
      }

      // [talked_to, issue date] required
      if (['payment'].includes(this.update_status)) {
        // Issue date needs to be null to start due to datepicker requirements
        if (!issue_date) {
          return false
        }

        const arr = [talked_to]

        if (this.inputs.check.value && !this.isValidCheckNumber(this.inputs.check.value)) {
          this.$set(this.inputs.check, 'valid', false)
          return false
        }
        this.$set(this.inputs.check, 'valid', true)

        return arr.every(item => item.length > 0)
      }

      // [talked_to, missing] required
      if (['missing paperwork'].includes(this.update_status)) {
        const arr = [missing, talked_to]
        return arr.every(item => item.length > 0)
      }

      // [talked_to, notes] required
      if (['shortpay reason'].includes(this.update_status)) {
        const arr = [notes, talked_to]
        return arr.every(item => item.length > 0)
      }

      // These are unique situations where depending on the invoice's status, we required
      // different input before submitting the update
      if (this.update_status === 'filed on bond') {
        if (this.status === 'approved') {
          return true
        }

        if (this.status === 'paid') {
          const arr = [notes, talked_to]
          return arr.every(item => item.length > 0)
        }
      }

      if (this.update_status === 'debtor claim') {
        // [talked_to] required
        if (this.status === 'approved') {
          const arr = [talked_to]
          return arr.every(item => item.length > 0)
        }

        // [notes, talked_to] required
        if (this.status === 'paid') {
          const arr = [notes, talked_to]
          return arr.every(item => item.length > 0)
        }
      }

      return false
    },
  },

  methods: {
    async addLogUpdate () {
      this.buttonDisabled = true
      this.progressStart()

      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2030
      // Check status of invoice before applying an update
      if (!await this.validateInvoiceStatus()) {
        this.buttonDisabled = false
        this.progressFail()
        return
      }

      try {
        // Approved status updates
        if (['payment', 'paid to client'].includes(this.update_status)) {
          let checkNumberOrACH

          if (this.inputs.paymentMethod === 'ach') {
            checkNumberOrACH = 'ach'
          }
          else if (this.inputs.check.value) {
            checkNumberOrACH = validator.escape(this.inputs.check.value)
          }
          else checkNumberOrACH = null

          await InvoiceUpdate.create({
            check_number_or_ach: checkNumberOrACH,
            invoice_id: this.id,
            // TODO: fix this ass fuckery backend
            // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1921
            'issue date': this.inputs.issue_date
              ? this.inputs.issue_date
              : null,
            notes: this.inputs.notes
              // Notes is typed by the user - blacklist code characters
              ? validator.blacklist(this.inputs.notes, '<>&"')
              : null,
            talked_to: this.inputs.talked_to
              // Talked To is typed by the user - blacklist code characters
              ? validator.blacklist(this.inputs.talked_to, '<>&/"')
              : null,
            talked_to_contact_method: this.inputs.talked_to_contact_method,
            update_status: this.convertStatusToBackendVerbiage(),
            update_type: this.status,
          })
        }
        else {
          await InvoiceUpdate.create({
            invoice_id: this.id,
            // TODO: fix this ass fuckery backend
            // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1921
            'issue date': this.inputs.issue_date,
            missing: this.inputs.missing
              // Missing is typed by the user - blacklist code characters
              ? validator.blacklist(this.inputs.missing, '<>&"')
              : null,
            notes: this.inputs.notes
              // Notes is typed by the user - blacklist code characters
              ? validator.blacklist(this.inputs.notes, '<>&"')
              : null,
            talked_to: this.inputs.talked_to
              // Talked To is typed by the user - blacklist code characters
              ? validator.blacklist(this.inputs.talked_to, '<>&/"')
              : null,
            talked_to_contact_method: this.inputs.talked_to_contact_method,
            update_status: this.convertStatusToBackendVerbiage(),
            update_type: this.update_status !== 'note'
              ? this.status
              : 'note'
          })
        }
        try {
          // Update UI w/ new invoice
          this.$store.commit('REPLACE_EXISTING_INVOICE', (await Invoice.get(this.id)).data)
          this.buttonDisabled = false
          // clear values
          Object.keys(this.inputs).forEach(key => {
            if (key === 'check') {
              this.$set(this.inputs, key, { valid: true, value: '' })
              return
            }
            this.$set(this.inputs, key, '')
          })
          this.closeModal()
          this.progressFinish()
        }
        catch (error) {
          this.captureSentryEvent(
            'Item Update "Add Update UI Update" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.buttonDisabled = false
          this.requestFailure({
            message: 'There was an issue retrieving the new version of the invoice',
            title: 'Request error',
          })
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Update "Add Update Create Update" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue adding an update to the invoice' })
        this.buttonDisabled = false
      }
    },

    closeModal () {
      this.$emit('close')
    },

    convertStatusToBackendVerbiage () {
      // Convert any options to the correct format for the backend
      // This allows us to display what we want to the user while sending what the
      // backend wants in the request

      if (this.update_status === 'note') return 'note'
      // The only update option for pending and declined is "${status} other"
      if (this.status === 'pending' || this.status === 'declined') return `${this.status} other`
      if (this.status === 'approved') {
        if (this.update_status === 'other') return 'approved other'
      }
      if (this.status === 'paid') {
        if (this.update_status === 'other') return 'paid other'
      }
      return this.update_status.toLowerCase()
    },

    async validateInvoiceStatus () {
      const invoice = (await Invoice.get(this.id)).data
      if (this.status !== invoice.status) {
        this.alertInfo(`
          This invoice is no longer ${this.status}. The last invoice update was
          by ${invoice.invoice_updates[0].user_name}
        `)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>
