var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInvoiceItem__FooterOption row" },
    [
      _c(
        "div",
        { staticClass: "BaseInvoiceItem__FooterOption__x-icon-wrapper" },
        [
          _c("i", {
            staticClass:
              "fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon",
            attrs: { tabindex: "0" },
            on: {
              click: _vm.closeModal,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.closeModal($event)
              }
            }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "BaseInvoiceItem__FooterOption__right row row--wrap" },
        [
          _c("base-input", {
            attrs: {
              instructions: _vm.instructions.PAYMENT_AMOUNT_INSTRUCTION,
              placeholder: "Payment amount",
              valid: _vm.amount.valid,
              id: "Testing__ItemPaymentAmountInput"
            },
            model: {
              value: _vm.amount.value,
              callback: function($$v) {
                _vm.$set(
                  _vm.amount,
                  "value",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "amount.value"
            }
          }),
          _c("base-input", {
            attrs: {
              instructions: _vm.instructions.CHECK_NUMBER_INSTRUCTION,
              placeholder: "Check #",
              valid: _vm.check.valid,
              id: "Testing__ItemPaymentCheckInput"
            },
            model: {
              value: _vm.check.value,
              callback: function($$v) {
                _vm.$set(
                  _vm.check,
                  "value",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "check.value"
            }
          }),
          _c("base-date-picker", {
            attrs: {
              instruction: _vm.instructions.BATCH_DATE_INSTRUCTION,
              placeholder: "Batch Date",
              valid: _vm.batch_date.valid,
              id: "Testing__ItemPaymentBatchDateInput"
            },
            model: {
              value: _vm.batch_date.value,
              callback: function($$v) {
                _vm.$set(_vm.batch_date, "value", $$v)
              },
              expression: "batch_date.value"
            }
          }),
          _c("base-toggle", {
            attrs: {
              "active-option": _vm.paymentMethod,
              toggles: _vm.paymentMethodToggles
            },
            on: {
              toggle: function($event) {
                _vm.paymentMethod = $event
              }
            }
          }),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              attrs: {
                disabled: !_vm.postPaymentReady || _vm.buttonDisabled,
                id: "Testing__ItemPaymentSubmitBtn"
              },
              on: {
                click: function($event) {
                  return _vm.postPayment(false)
                }
              }
            },
            [_vm._v("\n      Add Payment\n    ")]
          )
        ],
        1
      ),
      _vm.paymentConfirmationDisplay
        ? _c("base-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n      Payment (" +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(
                              _vm.transformStringNumberToNumber(
                                _vm.amount.value
                              ) / 100
                            )
                          ) +
                          ") is different than invoice amount (" +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(_vm.invoiceTotal)
                          ) +
                          "). Continue with payment?\n    "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-8",
                          attrs: {
                            id: "Testing__paymentConfirmationYesBtn",
                            disabled: _vm.buttonDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.postPayment(true)
                            }
                          }
                        },
                        [_vm._v("\n        Yes\n      ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white",
                          on: {
                            click: function($event) {
                              _vm.paymentConfirmationDisplay = false
                            }
                          }
                        },
                        [_vm._v("\n        No\n      ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              4155650341
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }