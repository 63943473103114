<template>
  <div class="BaseInvoiceTableRows">
    <template v-if="status === 'approved'">
      <base-approved-item
        v-for="invoice in sortedInvoices"
        :key="invoice.id"
        :expand-all="expandAll"
        :invoice="invoice"
        :user-is-client="userIsClient"
      />
    </template>
    <template v-if="status === 'declined'">
      <base-declined-item
        v-for="invoice in sortedInvoices"
        :key="invoice.id"
        :expand-all="expandAll"
        :invoice="invoice"
        :user-is-client="userIsClient"
      />
    </template>
    <template v-if="status === 'paid'">
      <base-paid-item
        v-for="invoice in sortedInvoices"
        :key="invoice.id"
        :expand-all="expandAll"
        :invoice="invoice"
        :user-is-client="userIsClient"
      />
    </template>
    <template v-if="status === 'pending'">
      <base-pending-item
        v-for="invoice in sortedInvoices"
        :key="invoice.id"
        :expand-all="expandAll"
        :invoice="invoice"
        :user-is-client="userIsClient"
      />
    </template>
    <template v-if="!sortedInvoices.length && mobile.isMobile">
      <label class="ml-16">No invoices</label>
    </template>
  </div>
</template>

<script>
// Components
import BaseApprovedItem from './base-approved-item.vue'
import BaseDeclinedItem from './base-declined-item.vue'
import BasePaidItem from './base-paid-item.vue'
import BasePendingItem from './base-pending-item.vue'

export default {
  name: 'BaseInvoiceTableRows',

  props: {
    expandAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortedInvoices: {
      type: Array,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    BaseApprovedItem,
    BaseDeclinedItem,
    BasePaidItem,
    BasePendingItem,
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },
}
</script>

<style lang="sass">
.BaseInvoiceTableRows
</style>