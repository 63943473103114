var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseClientTableHeader row" },
    [
      ["pending", "declined"].includes(_vm.status) ? [_vm._m(0)] : _vm._e(),
      _vm.status === "approved" ? [_vm._m(1)] : _vm._e(),
      _vm.status === "paid" ? [_vm._m(2)] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "BaseClientTableHeader__headers--A row" }, [
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Load #")
      ]),
      _c("label", { staticClass: "fc-trans fs-12 fw-med uppercase" }, [
        _vm._v("Flag")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Debtor")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Invoiced")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "BaseClientTableHeader__headers--B row" }, [
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Load #")
      ]),
      _c("label", { staticClass: "fc-trans fs-12 fw-med uppercase" }, [
        _vm._v("Flag")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Debtor")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Days")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Invoiced")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Funded")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "BaseClientTableHeader__headers--C row" }, [
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Load #")
      ]),
      _c("label", { staticClass: "fc-trans fs-12 fw-med uppercase" }, [
        _vm._v("Flag")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Debtor")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Invoiced")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Paid")
      ]),
      _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
        _vm._v("Diff")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }