<template>
  <div class="BaseClientTableHeader row">
    <!-- Header configuration A (See styling below) -->
    <template v-if="['pending', 'declined'].includes(status)">
      <div class="BaseClientTableHeader__headers--A row">
        <label class="fc-light fs-12 fw-med uppercase">Load #</label>
        <label class="fc-trans fs-12 fw-med uppercase">Flag</label>
        <label class="fc-light fs-12 fw-med uppercase">Debtor</label>
        <label class="fc-light fs-12 fw-med uppercase">Invoiced</label>
      </div>
    </template>

    <!-- Header configuration B (See styling below) -->
    <template v-if="status === 'approved'">
      <div class="BaseClientTableHeader__headers--B row">
        <label class="fc-light fs-12 fw-med uppercase">Load #</label>
        <label class="fc-trans fs-12 fw-med uppercase">Flag</label>
        <label class="fc-light fs-12 fw-med uppercase">Debtor</label>
        <label class="fc-light fs-12 fw-med uppercase">Days</label>
        <label class="fc-light fs-12 fw-med uppercase">Invoiced</label>
        <label class="fc-light fs-12 fw-med uppercase">Funded</label>
      </div>
    </template>

    <!-- Header configuration C (See styling below) -->
    <template v-if="status === 'paid'">
      <div class="BaseClientTableHeader__headers--C row">
        <label class="fc-light fs-12 fw-med uppercase">Load #</label>
        <label class="fc-trans fs-12 fw-med uppercase">Flag</label>
        <label class="fc-light fs-12 fw-med uppercase">Debtor</label>
        <label class="fc-light fs-12 fw-med uppercase">Invoiced</label>
        <label class="fc-light fs-12 fw-med uppercase">Paid</label>
        <label class="fc-light fs-12 fw-med uppercase">Diff</label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseClientTableHeader',

  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass">
/*
  Table Header Configs

  A: pending and declined invoices
  B: approved invoices
  C: paid invoices

  A: Load # | Flag | Debtor | Invoiced
  B: Load # | Flag | Debtor | Days | Invoiced | Advanced
  C: Load # | Flag | Debtor | Invoiced | Paid | Diff
/*
.BaseClientTableHeader

  &__headers

    &--A label
      &:nth-child(1) // Load #
        margin-right: 1rem
        width: rem(90px)
      &:nth-child(2) // Flag
        margin-right: 1rem
        width: rem(14px) // width of flag icon

        @media #{$large-and-up}
          width: rem(181px)
      &:nth-child(3) // Debtor
        margin-right: auto
        flex: 1 0 0
      &:last-child // Invoiced
        margin-right: 0
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit

    &--B label
      &:nth-child(1) // Load #
        margin-right: 1rem
        width: rem(90px)
      &:nth-child(2) // Flag
        margin-right: 1rem
        width: rem(14px) // width of flag icon

        @media #{$large-and-up}
          width: rem(181px)
      &:nth-child(3) // Debtor
        margin-right: auto
        flex: 1 0 0
      &:nth-child(4) // Days
        margin-right: 1rem
        width: rem(66px)
      &:nth-child(5) // Invoiced
        margin-right: 1rem
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit
      &:last-child // Advanced
        margin-right: 0
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit

    &--C label
      &:nth-child(1) // Load #
        margin-right: 1rem
        width: rem(90px)
      &:nth-child(2) // Flag
        margin-right: 1rem
        width: rem(14px) // width of flag icon

        @media #{$large-and-up}
          width: rem(181px)
      &:nth-child(3) // Debtor
        margin-right: auto
        flex: 1 0 0
      &:nth-child(4) // Invoiced
        margin-right: 1rem
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit
      &:nth-child(5) // Paid
        margin-right: 1rem
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit
      &:last-child // Diff
        margin-right: 0
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit
</style>