<template>
  <div class="BaseInvoiceItem__FooterOption row">
    <div class="BaseInvoiceItem__FooterOption__x-icon-wrapper">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon"
        tabindex="0"
      />
    </div>
    <div class="column">
      <div class="BaseInvoiceItem__FooterOption__right row row--wrap row--align-center">
        <label class="BaseInvoiceItem__FooterOption__label fs-14">Why is this invoice not being paid?</label>
      </div>

      <div class="column">
        <div class="row row--wrap">
          <base-flag
            v-for="(reasonOption, index) in defaults"
            :key="index"
            @toggle="selectReason(index)"
            :active="reasonOption.selected"
            class="BaseInvoiceItem__FooterOption__BaseFlag"
            :id="`Testing__NonPaymentReason-${index}`"
          >
            {{ reasonOption.reason }}
          </base-flag>
        </div>
        <base-button
          @click="addNonPayment"
          :disabled="!reason || buttonDisabled"
          class="BaseInvoiceItem__FooterOption__bottom-btn bg-blue fc-white"
          id="Testing__NonPaymentSubmitBtn"
        >
          Add Non-payment
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import {
  DebtorPayment,
  Invoice
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseFlag from './base-flag.vue'

export default {
  name: 'ItemNonPayment',

  components: {
    BaseButton,
    BaseFlag,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      buttonDisabled: false,
      defaults: [
        {
          reason: 'paid to client',
          selected: false,
        },
        {
          reason: 'duplicate invoice',
          selected: false,
        },
        {
          reason: 'over 90 days',
          selected: false,
        },
        {
          reason: 'debtor claim',
          selected: false,
        },
        {
          reason: 'did not deliver load',
          selected: false,
        },
        {
          reason: 'other',
          selected: false,
        },
      ],
      notes: '',
      reason: '',
    }
  },

  methods: {
    async addNonPayment () {
      const reason = (this.defaults.find(value => value.selected)).reason
      this.buttonDisabled = true
      this.progressStart()

      try {
        // get the invoice and check its status before trying to post the nonpayment
        const invoice = (await Invoice.get(this.id)).data
        if (invoice.status !== 'approved') {
          this.alertWarning('This invoice is no longer approved')
          this.$store.commit('DELETE_INVOICE', this.id)
          this.progressFinish()
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Nonpayment "Check Invoice Status" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue checking the invoice\'s current status'
        })
      }

      try {
        await DebtorPayment.create({
          amount: 0,
          batch_date: null,
          invoice_id: this.id,
          update_status: reason
        })
        this.requestSuccess({ message: 'Non-payment added' })
        // Remove invoice from local store if in /invoices/approved
        if (this.$route.name === 'approved') {
          this.$store.commit('DELETE_INVOICE', this.id)
        }
        // Update UI w/ new invoice if in /invoices/paid
        if (this.$route.name === 'paid') {
          try {
            this.$store.commit('REPLACE_EXISTING_INVOICE', (await Invoice.get(this.id)).data)
            this.buttonDisabled = false
          }
          catch (error) {
            this.captureSentryEvent(
              'Item Nonpayment "Add Nonpayment to PAID" Error',
              {
                config: error.config,
                data: this.$data,
                details: error,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.buttonDisabled = false
            this.requestFailure({
              message: 'There was an issue retrieving the new version of the invoice',
            })
          }
        }
        this.closeModal()
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Nonpayment "Add Nonpayment" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue posting a debtor payment',
        })
        this.buttonDisabled = false
      }
    },

    closeModal () {
      this.$emit('close')
    },

    selectReason (index) {
      const stateAtIndex = this.defaults[index].selected
      this.defaults.forEach(reason => this.$set(reason, 'selected', false))
      if (!stateAtIndex) {
        this.$set(this.defaults[index], 'selected', true)
        this.reason = this.defaults[index].reason
        return
      }
      this.reason = ''
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>
