import { render, staticRenderFns } from "./base-item-undo.vue?vue&type=template&id=51071c72&scoped=true&"
import script from "./base-item-undo.vue?vue&type=script&lang=js&"
export * from "./base-item-undo.vue?vue&type=script&lang=js&"
import style0 from "./base-item-undo.vue?vue&type=style&index=0&id=51071c72&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51071c72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frank/Documents/RacknerProjects/Bobtail/bobtail_reset_6-3/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51071c72')) {
      api.createRecord('51071c72', component.options)
    } else {
      api.reload('51071c72', component.options)
    }
    module.hot.accept("./base-item-undo.vue?vue&type=template&id=51071c72&scoped=true&", function () {
      api.rerender('51071c72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base-item-undo.vue"
export default component.exports