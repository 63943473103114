var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "BaseInvoiceTable" },
    [
      !["client", "debtor"].includes(this.sort)
        ? [
            _c(
              "div",
              { class: ["Table", { "Table--mobile": _vm.mobile.isMobile }] },
              [
                _vm._t("default"),
                !_vm.mobile.isMobile &&
                (_vm.invoices && _vm.invoices.invoices.length)
                  ? _c(
                      "div",
                      { staticClass: "Table__head-wrapper" },
                      [
                        _vm.userIsClient
                          ? _c("base-client-table-header", {
                              attrs: { status: _vm.status }
                            })
                          : _vm._e(),
                        !_vm.userIsClient
                          ? _c("base-employee-table-header", {
                              attrs: { status: _vm.status }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("base-invoice-table-rows", {
                  attrs: {
                    "expand-all": _vm.expandAll,
                    "sorted-invoices": _vm.sortedInvoices,
                    status: _vm.status,
                    "user-is-client": _vm.userIsClient
                  }
                })
              ],
              2
            )
          ]
        : _vm._l(_vm.sortedInvoices, function(category, index) {
            return _c("div", { key: index, staticClass: "mb-16" }, [
              _c(
                "div",
                {
                  staticClass: "Table__category-heading row row--align-center"
                },
                [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "label",
                      {
                        staticClass: "fs-16 capitalize",
                        attrs: {
                          "data-cy":
                            "base-invoice-table-category-label-" +
                            category.user.id,
                          title:
                            _vm.status === "approved"
                              ? category.user.payment_status_preferences
                              : null
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(category.user.name) +
                            "\n            "
                        ),
                        _vm.sort === "debtor" &&
                        _vm.status === "approved" &&
                        category.user.payment_status_preferences
                          ? _c("i", {
                              staticClass: "fa fa-info fa-15 ml-4",
                              attrs: {
                                "data-cy":
                                  "base-invoice-table-debtor-payment-status-preferences-tooltip"
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _c("label", { staticClass: "fc-light fs-14" }, [
                      _vm._v(_vm._s(_vm.formatPhoneNumber(category.user.phone)))
                    ])
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "fs-16 fw-med row row--align-center row--justify-end"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "Table__category-value-pre-lbl fs-16"
                          },
                          [_vm._v("Total:")]
                        ),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(category.total / 100)
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "section",
                { class: ["Table", { "Table--mobile": _vm.mobile.isMobile }] },
                [
                  _c(
                    "div",
                    { staticClass: "Table__head-wrapper" },
                    [
                      !_vm.userIsClient
                        ? _c("base-employee-table-header", {
                            attrs: { status: _vm.status }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("base-invoice-table-rows", {
                    attrs: {
                      "expand-all": _vm.expandAll,
                      "sorted-invoices": category.invoices,
                      status: _vm.status,
                      "user-is-client": _vm.userIsClient
                    }
                  })
                ],
                1
              )
            ])
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }