var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "Transition__fade" } }, [
    _c(
      "div",
      { staticClass: "BaseItemUndo row row--align-center" },
      [
        _c("i", {
          staticClass:
            "fa fa-add-circle fa-rotate-45 fa-20 fa-3 BaseInvoiceItem__FooterOption__x-icon",
          attrs: { tabindex: "0" },
          on: {
            click: _vm.closeModal,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.closeModal($event)
            }
          }
        }),
        _c(
          "label",
          { staticClass: "BaseInvoiceItem__FooterOption__undo-lbl fs-14" },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.isBuyout
                    ? "Delete this invoice and associated buyout?"
                    : "Send this back to pending?"
                ) +
                "\n    "
            )
          ]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white",
            attrs: {
              "data-cy": "base-item-undo-confirm-btn",
              disabled: _vm.buttonDisabled
            },
            on: { click: _vm.undoInvoice }
          },
          [_vm._v("\n      Yes\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }