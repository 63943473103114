var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInvoiceItem__FooterOption row" },
    [
      _c(
        "div",
        { staticClass: "BaseInvoiceItem__FooterOption__x-icon-wrapper" },
        [
          _c("i", {
            staticClass:
              "\n        fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon\n      ",
            attrs: { tabindex: "0" },
            on: {
              click: _vm.closeModal,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.closeModal($event)
              }
            }
          })
        ]
      ),
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.debtorRequestFinished
          ? _c(
              "div",
              {
                staticClass:
                  "BaseInvoiceItem__FooterOption__right row row--align-center row--wrap"
              },
              [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v("Who do you want to send this invoice to?")
                ]),
                _c("base-input", {
                  staticClass: "BaseInvoiceItem__FooterOption__note",
                  attrs: {
                    instructions:
                      _vm.instructions.INVOICE_DELIVERY_EMAILS_INSTRUCTION,
                    placeholder: "email(s)",
                    valid: _vm.emails.valid
                  },
                  model: {
                    value: _vm.emails.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.emails,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "emails.value"
                  }
                }),
                _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white",
                    attrs: {
                      disabled:
                        (!_vm.emails.value && !_vm.debtorEmail) ||
                        _vm.buttonDisabled
                    },
                    on: { click: _vm.shareInvoice }
                  },
                  [_vm._v("\n        Send\n      ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }