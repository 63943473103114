<template>
  <div class="BaseEmployeeTableHeader row">
    <!-- Header configuration A (See styling below) -->
    <template v-if="['pending', 'declined'].includes(status)">
      <div class="BaseEmployeeTableHeader__headers--A row">
        <label class="fc-light fs-12 fw-med uppercase">Load #</label>
        <label class="fc-trans fs-12 fw-med uppercase">Flag</label>
        <label class="fc-light fs-12 fw-med uppercase">Debtor</label>
        <label class="fc-light fs-12 fw-med uppercase">Client</label>
        <label class="fc-light fs-12 fw-med uppercase">Total</label>
      </div>
    </template>

    <!-- Header configuration B (See styling below) -->
    <template v-if="['approved', 'paid'].includes(status)">
      <div class="BaseEmployeeTableHeader__headers--B row">
        <label class="fc-light fs-12 fw-med uppercase">Load #</label>
        <label class="fc-trans fs-12 fw-med uppercase">Flag</label>
        <label class="fc-light fs-12 fw-med uppercase">Debtor</label>
        <label class="fc-light fs-12 fw-med uppercase">Client</label>
        <label class="fc-light fs-12 fw-med uppercase">Days</label>
        <label class="fc-light fs-12 fw-med uppercase">Total</label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseEmployeeTableHeader',

  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass">
/*
 * Table Header Configs
 *
 * A: pending and declined invoices
 * B: approved and paid invoices
 *
 * A: Load # | Flag | Debtor | Client | Total
 * B: Load # | Flag | Debtor | Client | Days | Total
 */

.BaseEmployeeTableHeader

  &__headers

    &--A label
      &:nth-child(1) // Load #
        margin-right: 1rem
        width: rem(90px)
      &:nth-child(2) // Flag
        margin-right: 1rem
        width: rem(14px) // width of flag icon

        @media #{$large-and-up}
          width: rem(181px)
      &:nth-child(3) // Debtor
        margin-right: 1rem
        flex: 1 0 0
      &:nth-child(4) // Client
        margin-right: 1rem
        width: rem(166px)
      &:last-child // Total
        margin-right: 0
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit

    &--B label
      &:nth-child(1) // Load #
        margin-right: 1rem
        width: rem(90px)
      &:nth-child(2) // Flag
        margin-right: 1rem
        width: rem(14px) // width of flag icon

        @media #{$large-and-up}
          width: rem(181px)
      &:nth-child(3) // Debtor
        margin-right: auto
        flex: 1 0 0
      &:nth-child(4) // Client
        margin-right: 1rem
        width: rem(166px)
      &:nth-child(5) // Days
        margin-right: 1rem
        width: rem(66px)
      &:last-child // Total
        margin-right: 0
        width: rem(100px) // Allows for $1,000,000.12 to JUST fit
</style>