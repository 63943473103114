var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseInvoiceItem__FooterOption row" }, [
    _c(
      "div",
      { staticClass: "BaseInvoiceItem__FooterOption__x-icon-wrapper" },
      [
        _c("i", {
          staticClass:
            "fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon",
          attrs: {
            "data-cy": "base-invoice-item-footer-option-x-btn",
            tabindex: "0"
          },
          on: {
            click: _vm.closeModal,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.closeModal($event)
            }
          }
        })
      ]
    ),
    _c(
      "div",
      { staticClass: "BaseInvoiceItem__FooterOption__right row row--wrap" },
      [
        _c("v-select", {
          attrs: {
            clearable: false,
            "data-cy": "item-update-type-select",
            options: _vm.statusOptions,
            placeholder: "Click to update status"
          },
          model: {
            value: _vm.update_status,
            callback: function($$v) {
              _vm.update_status = $$v
            },
            expression: "update_status"
          }
        }),
        _vm.update_status === "debtor claim"
          ? [
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "filed on bond"
          ? [
              _vm.status === "approved"
                ? [
                    _c("base-input", {
                      staticClass: "BaseInvoiceItem__FooterOption__note",
                      attrs: {
                        "data-cy": "item-update-notes-input",
                        placeholder: "Any notes?"
                      },
                      model: {
                        value: _vm.inputs.notes,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.inputs,
                            "notes",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "inputs.notes"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.status === "paid"
                ? [
                    _c("base-input", {
                      attrs: {
                        "data-cy": "item-update-talked-to-input",
                        placeholder: "Who did you talk to?"
                      },
                      model: {
                        value: _vm.inputs.talked_to,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.inputs,
                            "talked_to",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "inputs.talked_to"
                      }
                    }),
                    _c("base-toggle", {
                      attrs: {
                        "data-cy": "item-update-talked-to-toggle",
                        "emits-default-on-load": false,
                        toggles: _vm.talkedToToggle
                      },
                      on: {
                        toggle: function($event) {
                          _vm.inputs.talked_to_contact_method = $event
                        }
                      }
                    }),
                    _c("base-input", {
                      staticClass:
                        "BaseInvoiceItem__FooterOption__note--full-width",
                      attrs: {
                        "data-cy": "item-update-notes-input",
                        placeholder: "Any notes?"
                      },
                      model: {
                        value: _vm.inputs.notes,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.inputs,
                            "notes",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "inputs.notes"
                      }
                    })
                  ]
                : _vm._e()
            ]
          : _vm._e(),
        _vm.update_status === "missing paperwork"
          ? [
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-missing-input",
                  placeholder: "Missing"
                },
                model: {
                  value: _vm.inputs.missing,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "missing",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.missing"
                }
              }),
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "active-option": _vm.inputs.talked_to_contact_method,
                  "data-cy": "item-update-talked-to-toggle",
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "note"
          ? [
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "originals required"
          ? [
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "other"
          ? [
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "paid to client"
          ? [
              _c("base-toggle", {
                attrs: {
                  "active-option": _vm.inputs.paymentMethod,
                  "data-cy": "item-update-payment-method-toggle",
                  toggles: _vm.paymentMethodToggles
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.paymentMethod = $event
                  }
                }
              }),
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-check-number-input",
                  instructions: _vm.instructions.CHECK_NUMBER_INSTRUCTION,
                  placeholder: "Check #",
                  valid: _vm.inputs.check.valid
                },
                model: {
                  value: _vm.inputs.check.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs.check,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.check.value"
                }
              }),
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "active-option": _vm.inputs.talked_to_contact_method,
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "payment"
          ? [
              _c("base-date-picker", {
                attrs: {
                  name: "issue-date",
                  "data-cy": "item-update-payment-issue-date",
                  placeholder: "Payment Date"
                },
                model: {
                  value: _vm.inputs.issue_date,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "issue_date", $$v)
                  },
                  expression: "inputs.issue_date"
                }
              }),
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-check-number-input",
                  instructions: _vm.instructions.CHECK_NUMBER_INSTRUCTION,
                  placeholder: "Check #",
                  valid: _vm.inputs.check.valid
                },
                model: {
                  value: _vm.inputs.check.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs.check,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.check.value"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "active-option": _vm.inputs.paymentMethod,
                  "data-cy": "item-update-payment-method-toggle",
                  toggles: _vm.paymentMethodToggles
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.paymentMethod = $event
                  }
                }
              }),
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "processing"
          ? [
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "shortpay reason"
          ? [
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _vm.update_status === "unreadable"
          ? [
              _c("base-input", {
                attrs: {
                  "data-cy": "item-update-talked-to-input",
                  placeholder: "Who did you talk to?"
                },
                model: {
                  value: _vm.inputs.talked_to,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "talked_to",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.talked_to"
                }
              }),
              _c("base-toggle", {
                attrs: {
                  "data-cy": "item-update-talked-to-toggle",
                  "emits-default-on-load": false,
                  toggles: _vm.talkedToToggle
                },
                on: {
                  toggle: function($event) {
                    _vm.inputs.talked_to_contact_method = $event
                  }
                }
              }),
              _c("base-input", {
                staticClass: "BaseInvoiceItem__FooterOption__note--full-width",
                attrs: {
                  "data-cy": "item-update-notes-input",
                  placeholder: "Any notes?"
                },
                model: {
                  value: _vm.inputs.notes,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.inputs,
                      "notes",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "inputs.notes"
                }
              })
            ]
          : _vm._e(),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white",
            attrs: {
              "data-cy": "item-update-submit-btn",
              disabled: !_vm.requiredFields || _vm.buttonDisabled
            },
            on: { click: _vm.addLogUpdate }
          },
          [_vm._v("\n      Add Update\n    ")]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }