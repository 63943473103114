<template>
  <main class="BaseInvoiceTable">
    <!-- When the sort is time or total (clients only ever view this) -->
    <template v-if="!['client', 'debtor'].includes(this.sort)">
      <div :class="['Table', { 'Table--mobile': mobile.isMobile }]">
        <!-- If you want a heading and subheading in the top of the table -->
        <!-- Content passed here should have class: Table__slot-content -->
        <slot />

        <!-- Do not display the headers if the search returned 0 results -->
        <div
          v-if="!mobile.isMobile && (invoices && invoices.invoices.length)"
          class="Table__head-wrapper"
        >
          <base-client-table-header
            v-if="userIsClient"
            :status="status"
          />
          <base-employee-table-header
            v-if="!userIsClient"
            :status="status"
          />
        </div>

        <base-invoice-table-rows
          :expand-all="expandAll"
          :sorted-invoices="sortedInvoices"
          :status="status"
          :user-is-client="userIsClient"
        />
      </div>
    </template>

    <!-- When the sort is client or debtor (employee-only view) -->
    <template v-else>
      <div
        v-for="(category, index) in sortedInvoices"
        :key="index"
        class="mb-16"
      >
        <div class="Table__category-heading row row--align-center">
          <div class="column">
            <label
              class="fs-16 capitalize"
              :data-cy="`base-invoice-table-category-label-${category.user.id}`"
              :title="status === 'approved' ? category.user.payment_status_preferences : null"
            >
              {{ category.user.name }}
              <i
                v-if="
                  sort === 'debtor'
                    && status === 'approved'
                    && category.user.payment_status_preferences
                "
                class="fa fa-info fa-15 ml-4"
                data-cy="base-invoice-table-debtor-payment-status-preferences-tooltip"
              />
            </label>
            <label class="fc-light fs-14">{{ formatPhoneNumber(category.user.phone) }}</label>
          </div>
          <div class="column">
            <label class="fs-16 fw-med row row--align-center row--justify-end">
              <span class="Table__category-value-pre-lbl fs-16">Total:</span>
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(category.total / 100)
              }}
            </label>
          </div>
        </div>

        <section :class="['Table', { 'Table--mobile': mobile.isMobile }]">
          <div class="Table__head-wrapper">
            <base-employee-table-header
              v-if="!userIsClient"
              :status="status"
            />
          </div>

          <base-invoice-table-rows
            :expand-all="expandAll"
            :sorted-invoices="category.invoices"
            :status="status"
            :user-is-client="userIsClient"
          />
        </section>
      </div>
    </template>
  </main>
</template>

<script>
// Helpers
import { formatPhoneNumber } from '../utils/helpers'
// Components
import BaseClientTableHeader from './base-client-table-header.vue'
import BaseEmployeeTableHeader from './base-employee-table-header.vue'
import BaseInvoiceTableRows from './base-invoice-table-rows.vue'

export default {
  name: 'BaseInvoiceTable',

  components: {
    BaseClientTableHeader,
    BaseEmployeeTableHeader,
    BaseInvoiceTableRows,
  },

  props: {
    expandAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    sort: {
      type: String,
      required: false,
      default: null
    },
    status: {
      type: String,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
    whichInvoices: {
      type: String,
      required: false,
      default: 'invoices',
    },
  },

  computed: {
    invoices () {
      const invoiceInfo = this.$store.getters[this.whichInvoices]
      return {
        ...invoiceInfo,
        // This is required because of how invoice-search-results.vue works
        invoices: invoiceInfo.invoices.filter(invoice => invoice.status === this.status),
      }
    },

    mobile () {
      return this.$store.getters.mobile
    },

    sortedInvoices () {
      if (
        !!this.invoices
        && this.invoices.invoices.length
        && (this.sort === 'client' || this.sort === 'debtor')
      ) {
        const userType = this.sort === 'client' ? 'client' : 'debtor'
        if (this.invoices.stats[`${userType}_total_by_id`]) {
          // Break this.invoices.invoices into an array of arrays representing the invoices for each client
          const categories = []
          this.invoices.invoices.forEach(invoice => {
            // invoiceStats[`${userType}_total_by_id`] === facets_stats.[client/debtor]_total_by_id
            // invoice[userType].id === the ID of the current invoice's userType user
            const invoiceUserTotal = this.invoices.stats[`${userType}_total_by_id`][invoice[userType].id]
            // Add the very first invoice to our categories (only runs once)
            if (!categories.length) {
              categories.push({
                invoices: [invoice],
                // Match the category user with our invoiceStats on it
                total: invoiceUserTotal,
                user: invoice[userType],
              })
              return
            }
            // Find a matching category using client.id
            const existingCategory = categories.filter(category => {
              return category.user.id === invoice[userType].id
            })[0]
            // Add new category if no match
            if (!existingCategory) {
              categories.push({
                invoices: [invoice],
                total: invoiceUserTotal,
                user: invoice[userType],
              })
              return
            }
            // Add to match (add to category invoices and total)
            existingCategory.invoices.push(invoice)
          })
          // Even though the backend is delivering the invoices in proper order
          // we need to ensure the categories are sorted by the user's TOTAL amount and not just the
          // amount currently represented by the invoices on the page
          return categories.sort((a, b) => {
            if (a.total > b.total) {
              return -1
            }
            if (a.total < b.total) {
              return 1
            }
            return 0
          })
        }
        return null
      }
      return this.invoices.invoices
    },
  },

  methods: {
    formatPhoneNumber,
  },
}
</script>

<style lang="sass">
@import '../styles/tables'

.BaseInvoiceTable

  // Invoice items do not need the border bottom on the column headers
  .Table__head-wrapper
    border-bottom: none
</style>