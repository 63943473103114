var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseEmployeeTableHeader row" },
    [
      ["pending", "declined"].includes(_vm.status) ? [_vm._m(0)] : _vm._e(),
      ["approved", "paid"].includes(_vm.status) ? [_vm._m(1)] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "BaseEmployeeTableHeader__headers--A row" },
      [
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Load #")
        ]),
        _c("label", { staticClass: "fc-trans fs-12 fw-med uppercase" }, [
          _vm._v("Flag")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Debtor")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Client")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Total")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "BaseEmployeeTableHeader__headers--B row" },
      [
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Load #")
        ]),
        _c("label", { staticClass: "fc-trans fs-12 fw-med uppercase" }, [
          _vm._v("Flag")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Debtor")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Client")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Days")
        ]),
        _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
          _vm._v("Total")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }