<template>
  <div class="BaseInvoiceItem__FooterOption row">
    <div class="BaseInvoiceItem__FooterOption__x-icon-wrapper">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon"
        tabindex="0"
      />
    </div>

    <div class="BaseInvoiceItem__FooterOption__right row row--wrap">
      <base-input
        v-model.trim="amount.value"
        :instructions="instructions.PAYMENT_AMOUNT_INSTRUCTION"
        :placeholder="'Payment amount'"
        :valid="amount.valid"
        id="Testing__ItemPaymentAmountInput"
      />
      <base-input
        v-model.trim="check.value"
        :instructions="instructions.CHECK_NUMBER_INSTRUCTION"
        :placeholder="'Check #'"
        :valid="check.valid"
        id="Testing__ItemPaymentCheckInput"
      />
      <base-date-picker
        v-model="batch_date.value"
        :instruction="instructions.BATCH_DATE_INSTRUCTION"
        :placeholder="'Batch Date'"
        :valid="batch_date.valid"
        id="Testing__ItemPaymentBatchDateInput"
      />
      <base-toggle
        @toggle="paymentMethod = $event"
        :active-option="paymentMethod"
        :toggles="paymentMethodToggles"
      />
      <base-button
        @click="postPayment(false)"
        :disabled="!postPaymentReady || buttonDisabled"
        class="bg-blue fc-white"
        id="Testing__ItemPaymentSubmitBtn"
      >
        Add Payment
      </base-button>
    </div>

    <base-modal v-if="paymentConfirmationDisplay">
      <template v-slot:label>
        Payment ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(transformStringNumberToNumber(amount.value) / 100)
        }}) is different than invoice amount ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(invoiceTotal)
        }}). Continue with payment?
      </template>
      <template v-slot:actions>
        <base-button
          @click="postPayment(true)"
          class="bg-blue fc-white mr-8"
          id="Testing__paymentConfirmationYesBtn"
          :disabled="buttonDisabled"
        >
          Yes
        </base-button>
        <base-button
          @click="paymentConfirmationDisplay = false"
          class="bg-light fc-white"
        >
          No
        </base-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
/* eslint-disable object-shorthand */

// Packages
import validator from 'validator'
// Helpers
import {
  DebtorPayment,
  Invoice
} from '../utils/api'
import { transformStringNumberToNumber } from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseInput from './base-input.vue'
import BaseModal from './base-modal.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'ItemPayment',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
    BaseModal,
    BaseToggle,
  },

  mixins: [ValidationMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
    invoiceStatus: {
      type: String,
      required: true
    },
    invoiceTotal: {
      type: Number,
      required: false,
      default: 0
    }
  },

  created () {
    this.$set(this.batch_date, 'value', this.$store.getters.batchDate)
  },

  data () {
    return {
      amount: {
        valid: true,
        value: null
      },
      batch_date: {
        valid: true,
        value: null,
      },
      buttonDisabled: false,
      check: {
        valid: true,
        value: ''
      },
      paymentConfirmationDisplay: false,
      paymentMethod: 'check',
      paymentMethodToggles: ['Check', 'ACH'],
    }
  },

  watch: {
    'check.value' (newValue) {
      if (newValue.length) {
        this.paymentMethod = 'check'
      }
    },

    paymentMethod (newValue) {
      if (newValue === 'ach') this.$set(this.check, 'value', '')
    }
  },

  computed: {
    postPaymentReady () {
      if (this.amount.value) {
        if (this.check.value || this.paymentMethod === 'ach') {
          if (this.batch_date.value) {
            return true
          }
        }
      }
      return false
    },
  },

  methods: {
    closeModal () {
      this.$emit('close')
    },

    async postPayment (bypassConfirmationCheck = false) {
      if (!this.validation()) {
        return
      }

      // If posting a payment different than invoice amount to an approved invoice
      if (!bypassConfirmationCheck) {
        if (this.invoiceStatus === 'approved') {
          if (transformStringNumberToNumber(this.amount.value) !== transformStringNumberToNumber(this.invoiceTotal)) {
            this.paymentConfirmationDisplay = true
            return
          }
        }
      }

      this.buttonDisabled = true
      this.progressStart()

      try {
        // get the invoice and check its status before trying to post the payment
        const invoice = (await Invoice.get(this.id)).data
        if (invoice.status !== this.invoiceStatus) {
          this.alertWarning(`This invoice is no longer ${this.invoiceStatus}`)
          this.$store.commit('DELETE_INVOICE', this.id)
          this.progressFinish()
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Payment "Check Invoice Status" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the updated version of this invoice'
        })
      }

      try {
        await DebtorPayment.create({
          amount: transformStringNumberToNumber(this.amount.value),
          batch_date: this.batch_date.value,
          check_number: this.paymentMethod === 'ach'
            ? null
            : validator.escape(this.check.value),
          invoice_id: this.id,
          transaction_type: this.paymentMethod === 'ach'
            ? 'ach'
            : 'lockbox',
          update_type: 'approved',
        })
        this.requestSuccess({ message: 'Payment posted' })
        // Store the batch date for future use
        this.$store.commit('STORE_BATCH_DATE', this.batch_date.value)
        // Remove invoice from local store if in /invoices/approved
        if (this.$route.name === 'approved') {
          this.$store.commit('DELETE_INVOICE', this.id)
        }
        // Update UI w/ new invoice if in /invoices/paid
        if (this.$route.name === 'paid') {
          this.progressStart()

          try {
            const invoice = (await Invoice.get(this.id)).data

            // Since payments are made in the queue, the invoice won't be fully updated immediately
            // So, we're going to mock setting the debtor_status_payment from 'nonpayment' to:
            // 'fully_paid' : debtorPaymentsTotal === approved_accounts_receivable_amount
            // 'overpay'    : debtorPaymentsTotal > approved_accounts_receivable_amount
            // 'shortpay'   : debtorPaymentsTotal < approved_accounts_receivable_amount
            const debtorPaymentsTotal = invoice.total_debtor_payments // Instantly accurate
            const invoiceTotal = invoice.approved_accounts_receivable_amount // Always accurate
            let statusDebtorPayment // Eventually accurate

            if (debtorPaymentsTotal > invoiceTotal) statusDebtorPayment = 'overpay'
            else if (debtorPaymentsTotal < invoiceTotal) statusDebtorPayment = 'shortpay'
            else statusDebtorPayment = 'fully_paid'

            this.$store.commit('REPLACE_EXISTING_INVOICE', Object.assign(invoice, {
              status_debtor_payment: statusDebtorPayment,
            }))
            this.buttonDisabled = false
            // Clear values = if in /paid, the values need cleared
            this.amount.value = null
            this.check.value = ''
            this.batch_date.value = ''
            this.paymentMethod = 'check'
            this.progressFinish()
          }
          catch (error) {
            this.captureSentryEvent(
              'Item Payment "Payment to PAID" Error',
              {
                config: error.config,
                data: this.$data,
                details: error,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.buttonDisabled = false
            this.requestFailure({
              message: 'There was an issue retrieving the new version of the invoice',
              title: 'Request error',
            })
          }
        }
        this.closeModal()
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Payment "Add Payment" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue posting a debtor payment',
        })
        this.buttonDisabled = false
      }
    },

    // Must be spread here to use in the template
    transformStringNumberToNumber (x) {
      return transformStringNumberToNumber(x)
    },

    validation () {
      let valid = true

      if (!this.amount.value || !this.isValidPayAmount(this.amount.value.toString())) {
        this.$set(this.amount, 'valid', false)
        valid = false
      } else {
        this.$set(this.amount, 'valid', true)
      }

      if (this.paymentMethod === 'check') {
        if (!this.check.value || !this.isValidCheckNumber(this.check.value)) {
          this.$set(this.check, 'valid', false)
          valid = false
        } else {
          this.$set(this.check, 'valid', true)
        }
      }

      if (!this.isValidBatchDate(this.batch_date.value)) {
        this.$set(this.batch_date, 'valid', false)
        valid = false
      } else {
        this.$set(this.batch_date, 'valid', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
</style>
