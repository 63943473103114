var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "BaseInvoiceItem",
        {
          "BaseInvoiceItem--flagged":
            _vm.invoice.flagged && _vm.invoice.last_flag_message,
          "BaseInvoiceItem--mobile": _vm.mobile.isMobile
        }
      ],
      attrs: { "data-cy": "base-invoice-item-" + _vm.invoice.load_number }
    },
    [
      _c(
        "div",
        {
          class: [
            "BaseInvoiceItem__top-container BaseInvoiceItem__top-container--paid",
            {
              "BaseInvoiceItem__top-container--paid-client": _vm.userIsClient,
              "BaseInvoiceItem__top-container--paid-employee": !_vm.userIsClient,
              "BaseInvoiceItem__top-container--unclickable":
                !_vm.mobile.isMobile && _vm.userIsClient
            }
          ],
          attrs: { tabindex: "0" },
          on: {
            click: _vm.expand,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.expand($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "BaseInvoiceItem__top-container--desktop row" },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    staticClass: "fs-14 fw-med width-100",
                    attrs: {
                      "data-cy":
                        "base-invoice-item-load-" + _vm.invoice.load_number,
                      title: _vm.invoice.load_number
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.invoice.load_number.length > 10
                            ? _vm.invoice.load_number.slice(0, 10) + "..."
                            : _vm.invoice.load_number
                        ) +
                        "\n        "
                    )
                  ]
                ),
                !_vm.userIsClient
                  ? _c(
                      "label",
                      {
                        staticClass: "fc-light fs-12",
                        attrs: {
                          "data-cy": "display-id",
                          title: _vm.invoice.id
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.invoice.display_id) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "column" }, [
                _vm.invoice.status_debtor_payment &&
                _vm.invoice.status_debtor_payment !== "fully_paid"
                  ? _c(
                      "label",
                      {
                        class: [
                          "BaseInvoiceItem__flag-lbl fs-12 uppercase",
                          {
                            "bg-green":
                              _vm.invoice.status_debtor_payment === "overpay",
                            "bg-red":
                              _vm.invoice.status_debtor_payment ===
                                "shortpay" ||
                              _vm.invoice.status_debtor_payment === "nonpayment"
                          }
                        ],
                        attrs: { "data-cy": "base-invoice-item-flag" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.invoice.status_debtor_payment) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "BaseInvoiceItem__flag-icon column mr-16" },
                  [
                    _vm.invoice.status_debtor_payment &&
                    _vm.invoice.status_debtor_payment !== "fully_paid"
                      ? _c("i", {
                          class: [
                            "fa fa-flag fa--none fs-18",
                            {
                              "fc-green":
                                _vm.invoice.status_debtor_payment === "overpay",
                              "fc-red":
                                _vm.invoice.status_debtor_payment ===
                                  "shortpay" ||
                                _vm.invoice.status_debtor_payment ===
                                  "nonpayment"
                            }
                          ]
                        })
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  {
                    staticClass: "column column--width-auto",
                    attrs: {
                      title: _vm.invoice.debtor.broker
                        ? "MC: " + _vm.invoice.debtor.broker.mc
                        : "MC: Not a broker"
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 uppercase",
                          {
                            clickable:
                              !_vm.userIsClient &&
                              _vm.invoice.debtor.id !== 2 &&
                              _vm.invoice.debtor.name.toLowerCase() !==
                                "debtor not found"
                          }
                        ],
                        attrs: { tabindex: !_vm.userIsClient ? 0 : null },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goToDebtorProfile($event)
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            return _vm.goToDebtorProfile($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.sanitizeAlgoliaNames(
                                _vm.invoice.debtor_selected_name
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _c("label", { staticClass: "fc-light fs-12" }, [
                      _vm._v(
                        _vm._s(_vm.formatPhoneNumber(_vm.invoice.debtor.phone))
                      )
                    ])
                  ]
                )
              ]),
              !_vm.userIsClient
                ? _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        attrs: { title: "MC: " + _vm.invoice.client.mc }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "clickable fs-14",
                            attrs: { tabindex: "0" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToClientProfile($event)
                              },
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.goToClientProfile($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.invoice.client.shortened_name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("label", { staticClass: "fc-light fs-12" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatPhoneNumber(_vm.invoice.client.phone)
                            )
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              !_vm.userIsClient
                ? _c("div", { staticClass: "column" }, [
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14",
                          {
                            "fc-red":
                              _vm.invoice.aging >= 45 &&
                              _vm.invoice.status_debtor_payment !== "nonpayment"
                          }
                        ],
                        attrs: {
                          "data-cy": "base-invoice-item-days-to-pay-label"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.invoice.status_debtor_payment === "nonpayment"
                                ? "NP"
                                : _vm.invoice.aging
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.invoice.amounts.total)
                      ) +
                      "\n        "
                  )
                ]),
                !_vm.userIsClient &&
                (_vm.invoice.amounts.overpaid || _vm.invoice.amounts.shortpaid)
                  ? _c(
                      "label",
                      {
                        class: [
                          "fs-14",
                          {
                            "fc-green": _vm.invoice.amounts.overpaid,
                            "fc-red": _vm.invoice.amounts.shortpaid
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.invoice.amounts.overpaid
                                ? Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(_vm.overpaidDiff)
                                : Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(_vm.shortpaidDiff)
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm.userIsClient
                ? _c("div", { staticClass: "column" }, [
                    _vm.invoice.status_debtor_payment !== "fully_paid"
                      ? _c("label", { staticClass: "fs-14" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.invoice.amounts.overpaid
                                  ? Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD"
                                    }).format(_vm.overpaidAmt)
                                  : Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD"
                                    }).format(_vm.shortpaidAmt)
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.userIsClient
                ? _c("div", { staticClass: "column" }, [
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14",
                          {
                            "fc-green": _vm.invoice.amounts.overpaid,
                            "fc-red": _vm.invoice.amounts.shortpaid
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              // If overpaid, ((total + overpaid) - total)
                              // If shortpaid, (total - (total - shortpaid))
                              _vm.invoice.amounts.overpaid
                                ? Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(_vm.overpaidDiff)
                                : Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(_vm.shortpaidDiff)
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            { staticClass: "BaseInvoiceItem__top-container--mobile row" },
            [
              _c("div", { staticClass: "column mr-16" }, [
                _c(
                  "label",
                  { staticClass: "fc-mid fs-14 uppercase width-100" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.sanitizeAlgoliaNames(
                            _vm.invoice.debtor_selected_name
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c("label", { staticClass: "fc-light fs-12 width-100" }, [
                  _vm._v(_vm._s(_vm.invoice.load_number))
                ])
              ]),
              _c("div", { staticClass: "column mr-16" }, [
                _vm.invoice.status_debtor_payment &&
                _vm.invoice.status_debtor_payment !== "fully_paid"
                  ? _c("i", {
                      class: [
                        "fa fa-flag fa--none fs-14",
                        {
                          "fc-green":
                            _vm.invoice.status_debtor_payment === "overpay",
                          "fc-red":
                            _vm.invoice.status_debtor_payment === "shortpay" ||
                            _vm.invoice.status_debtor_payment === "nonpayment"
                        }
                      ]
                    })
                  : _vm._e()
              ]),
              _c("div", { staticClass: "column column--align-end" }, [
                _vm.invoice.status_debtor_payment !== "fully_paid"
                  ? _c("label", { staticClass: "fs-14" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.invoice.amounts.overpaid
                              ? Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.overpaidAmt)
                              : Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.shortpaidAmt)
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "label",
                  {
                    class: [
                      "fs-14",
                      {
                        "fc-green": _vm.invoice.amounts.overpaid,
                        "fc-red": _vm.invoice.amounts.shortpaid
                      }
                    ]
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          // If overpaid, ((total + overpaid) - total)
                          // If shortpaid, (total - (total - shortpaid))
                          _vm.invoice.amounts.overpaid
                            ? Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(_vm.overpaidDiff)
                            : Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(_vm.shortpaidDiff)
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            ]
          ),
          _vm.userIsClient
            ? _c("div", { staticClass: "row" }, [
                _c("label", { staticClass: "fc-light fs-14" }, [
                  _vm._v(_vm._s(_vm.invoice.memo))
                ])
              ])
            : _vm._e()
        ]
      ),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bottomExpanded,
                expression: "bottomExpanded"
              }
            ],
            staticClass: "column",
            attrs: { "data-cy": "base-invoice-item-expanded" }
          },
          [
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              _c(
                "div",
                { staticClass: "BaseInvoiceItem__expanded-content column" },
                [
                  _c("hr", { staticClass: "Divider" }),
                  _c("invoice-updates", {
                    attrs: {
                      "default-entries": 3,
                      updates: _vm.invoice.updates
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                class: [
                  "BaseInvoiceItem__footer row",
                  {
                    bg: _vm.toggles.paymentDetails && !_vm.userIsClient
                  }
                ]
              },
              [
                _c(
                  "transition",
                  {
                    attrs: { mode: "out-in", name: "Transition__opacity-fade" }
                  },
                  [
                    _vm.modalHidden && !_vm.additionalOptionsVisible
                      ? _c(
                          "div",
                          {
                            key: "buttons",
                            class: [
                              "row row--align-center",
                              { "row--justify-between": _vm.mobile.isMobile }
                            ]
                          },
                          [
                            !_vm.userIsClient
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--newupdate fs-14 mr-30",
                                    attrs: {
                                      "data-cy":
                                        "base-invoice-item-add-update-btn"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.toggles.update = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-add-circle"
                                    }),
                                    _vm._v(
                                      "\n              Add Update\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.userIsClient
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--postpayment fs-14 mr-30",
                                    attrs: {
                                      "data-cy":
                                        "base-invoice-item-post-payment-btn"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.toggles.payment = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-payment" }),
                                    _vm._v(
                                      "\n              Post Payment\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--share fs-14 mr-30",
                                on: {
                                  click: function($event) {
                                    _vm.toggles.share = true
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-link" }),
                                _vm._v("\n              Share\n            ")
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30",
                                on: { click: _vm.viewInvoice }
                              },
                              [
                                _c("i", { staticClass: "fa fa-view fa-3" }),
                                _vm._v("\n              View\n            ")
                              ]
                            ),
                            !_vm.userIsClient
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--view fs-14 mr-30",
                                    attrs: {
                                      "data-cy":
                                        "base-invoice-item-payment-details-btn"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.toggles.paymentDetails = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-ellipsis" }),
                                    _vm._v(
                                      "\n              Payment Details\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--options fs-14 ml-auto",
                                on: {
                                  click: function($event) {
                                    _vm.additionalOptionsVisible = true
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-dots fs-14" }),
                                _vm._v("\n              More\n            ")
                              ]
                            )
                          ]
                        )
                      : _vm.additionalOptionsVisible
                      ? _c(
                          "div",
                          {
                            key: "additional-buttons",
                            class: [
                              "row row--align-center",
                              { "row--justify-between": _vm.mobile.isMobile }
                            ]
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "fs-14 mr-30",
                                on: {
                                  click: function($event) {
                                    _vm.additionalOptionsVisible = false
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-back" }),
                                _vm._v("\n              Back\n            ")
                              ]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.userIsClient,
                                    expression: "!userIsClient"
                                  }
                                ],
                                staticClass:
                                  "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--regenerate fs-14 mr-30",
                                attrs: {
                                  disabled: _vm.disableRegenerateButton
                                },
                                on: { click: _vm.regenerateDocs }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-invoice-updates fa-3"
                                }),
                                _vm._v(
                                  "\n              Regenerate PDF\n            "
                                )
                              ]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.userIsClient &&
                                      _vm.invoice.status_client_payment ===
                                        "not_paid",
                                    expression:
                                      "!userIsClient && invoice.status_client_payment === 'not_paid'"
                                  }
                                ],
                                staticClass:
                                  "BaseInvoiceItem__footer-btn BaseInvoiceItem__footer-btn--undo fs-14",
                                on: {
                                  click: function($event) {
                                    _vm.additionalOptionsVisible = false
                                    _vm.toggles.undo = true
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-repeat" }),
                                _vm._v("\n              Undo\n            ")
                              ]
                            )
                          ]
                        )
                      : _c(
                          "div",
                          {
                            key: "respectiveComponents",
                            staticClass: "width-100"
                          },
                          [
                            _vm.toggles.update && !_vm.userIsClient
                              ? _c("item-update", {
                                  attrs: {
                                    id: _vm.invoice.id,
                                    status: _vm.invoice.status
                                  },
                                  on: { close: _vm.closeModal }
                                })
                              : _vm._e(),
                            _vm.toggles.payment && !_vm.userIsClient
                              ? _c("item-payment", {
                                  attrs: {
                                    id: _vm.invoice.id,
                                    "invoice-status": "paid",
                                    "invoice-total": _vm.invoice.amounts.total
                                  },
                                  on: { close: _vm.closeModal }
                                })
                              : _vm._e(),
                            _vm.toggles.share
                              ? _c("item-share", {
                                  attrs: {
                                    id: _vm.invoice.debtor.id,
                                    "invoice-id": _vm.invoice.id
                                  },
                                  on: { close: _vm.closeModal }
                                })
                              : _vm._e(),
                            _vm.toggles.undo && !_vm.userIsClient
                              ? _c("base-item-undo", {
                                  attrs: {
                                    id: _vm.invoice.id,
                                    "is-buyout": _vm.invoice.is_buyout,
                                    "status-back-to": "approved"
                                  },
                                  on: { close: _vm.closeModal }
                                })
                              : _vm._e(),
                            _vm.toggles.paymentDetails && !_vm.userIsClient
                              ? _c("item-payment-details", {
                                  attrs: { "invoice-id": _vm.invoice.id },
                                  on: { close: _vm.closeModal }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }