var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseInvoiceItem__FooterOption row" }, [
    _c(
      "div",
      { staticClass: "BaseInvoiceItem__FooterOption__x-icon-wrapper" },
      [
        _c("i", {
          staticClass:
            "fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon",
          attrs: { tabindex: "0" },
          on: {
            click: _vm.closeModal,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.closeModal($event)
            }
          }
        })
      ]
    ),
    _c("div", { staticClass: "column" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "div",
            { staticClass: "row row--wrap" },
            _vm._l(_vm.defaults, function(reasonOption, index) {
              return _c(
                "base-flag",
                {
                  key: index,
                  staticClass: "BaseInvoiceItem__FooterOption__BaseFlag",
                  attrs: {
                    active: reasonOption.selected,
                    id: "Testing__NonPaymentReason-" + index
                  },
                  on: {
                    toggle: function($event) {
                      return _vm.selectReason(index)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(reasonOption.reason) + "\n        "
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "base-button",
            {
              staticClass:
                "BaseInvoiceItem__FooterOption__bottom-btn bg-blue fc-white",
              attrs: {
                disabled: !_vm.reason || _vm.buttonDisabled,
                id: "Testing__NonPaymentSubmitBtn"
              },
              on: { click: _vm.addNonPayment }
            },
            [_vm._v("\n        Add Non-payment\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "BaseInvoiceItem__FooterOption__right row row--wrap row--align-center"
      },
      [
        _c(
          "label",
          { staticClass: "BaseInvoiceItem__FooterOption__label fs-14" },
          [_vm._v("Why is this invoice not being paid?")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }