<template>
  <div class="ItemPaymentDetails row">
    <div class="ItemPaymentDetails__close-icon-wrapper column column--width-auto">
      <i
        @click="closeModal"
        @keyup.enter="closeModal"
        class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 fa--none BaseInvoiceItem__FooterOption__x-icon"
        tabindex="0"
      />
    </div>

    <div class="ItemPaymentDetails__content column">
      <div class="row">
        <label class="Table__header-lbl Table__header-lbl--ItemPaymentDetails">POSTED DATE</label>
        <label
          :class="['Table__header-lbl Table__header-lbl--ItemPaymentDetails', {
            'ItemPaymentDetails__expanded-header': paymentUpdate
          }]"
        >BATCH DATE</label>
        <label
          :class="['Table__header-lbl Table__header-lbl--ItemPaymentDetails', {
            'ItemPaymentDetails__expanded-header': paymentUpdate
          }]"
        >AMOUNT</label>
        <label
          :class="['Table__header-lbl Table__header-lbl--ItemPaymentDetails', {
            'ItemPaymentDetails__expanded-header': paymentUpdate
          }]"
        >CHECK #</label>
        <label class="Table__header-lbl Table__header-lbl--ItemPaymentDetails" />
      </div>

      <transition-group
        class="column"
        name="Transition__opacity-fade"
        tag="div"
      >
        <div
          v-for="(payment, index) in debtorPayments"
          :key="index"
          class="Table__item-wrapper Table__item-wrapper--ItemPaymentDetails row row--align-center"
          :data-cy="`item-payment-details-${index}`"
        >
          <!-- POSTED DATE -->
          <div
            :class="['column', {
              'ItemPaymentDetails__item-border': !payment.editing
            }]"
          >
            <label class="fs-14 uppercase">
              {{ payment.created_at | datetime('MM/DD/YYYY') }}
            </label>
          </div>

          <!-- BATCH DATE -->
          <div
            :class="['column', {
              'ItemPaymentDetails__item-border': !payment.editing
            }]"
          >
            <label
              v-if="!payment.editing"
              class="fs-14 uppercase"
            >
              {{ payment.batch_date | datetime('MM/DD/YYYY') }}
            </label>
            <base-date-picker
              v-else
              v-model="paymentUpdate.batch_date"
              class="ItemPaymentDetails__input"
              data-cy="item-payment-details-batch-date-input"
              :placeholder="'Batch Date'"
            />
          </div>

          <!-- AMOUNT -->
          <div
            :class="['column', {
              'ItemPaymentDetails__item-border': !payment.editing
            }]"
          >
            <label class="fs-14">
              {{
                Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format((payment.amount / 100))
              }}
            </label>
          </div>

          <!-- CHECK NUMBER -->
          <div
            :class="['column', {
              'ItemPaymentDetails__item-border': !payment.editing
            }]"
          >
            <label
              v-if="!payment.editing"
              class="fs-14"
            >
              {{ payment.transaction_type === 'ach' ? 'ACH' : payment.check_number }}
            </label>
            <div
              v-else
              class="row"
            >
              <base-toggle
                @toggle="updateTransactionType"
                :active-option="paymentMethod"
                :toggles="paymentMethodToggles"
                class="mr-10"
              />
              <base-input
                v-if="paymentMethod === 'check'"
                v-model.trim="paymentUpdate.check_number"
                class="ItemPaymentDetails__input"
              />
            </div>
          </div>

          <!-- EDIT & DELETE Buttons -->
          <div class="column">
            <div
              v-if="!payment.editing"
              class="row row--align-center"
            >
              <button
                @click="openEditPayment(payment)"
                class="ItemPaymentDetails__action-btn"
                data-cy="item-payment-details-edit-btn"
              >
                <i
                  class="fa fa-edit fa--none fa-15 fc-light"
                />
              </button>
              <button
                v-if="payment.allowDelete"
                @click="openDeletePayment(payment)"
                data-cy="item-payment-details-delete-btn"
                class="ItemPaymentDetails__action-btn"
              >
                <i
                  class="fa fa-trash-can fa--none fa-15 fc-light"
                />
              </button>
            </div>
            <div
              v-else
              class="row"
            >
              <base-button
                @click="saveUpdate"
                @keydown.enter="saveUpdate"
                data-cy="item-payment-details-save-btn"
                :disabled="saveDisabled"
                class="bg-blue fc-white mr-6"
              >
                Save
              </base-button>
              <base-button
                @click="cancelUpdate"
                @keydown.enter="cancelUpdate"
                class="bg-light fc-white"
              >
                Cancel
              </base-button>
            </div>
          </div>
        </div>
      </transition-group>
      <transition name="Transition__fade">
        <base-item-delete
          v-if="deleting"
          @cancel="cancelDelete"
          @delete="deletePayment"
          :confirm-text="'Yes, delete payment'"
          :delete-text="
            `Are you sure you would like to delete payment for ${
              Intl
                .NumberFormat('en-US', { style: 'currency', currency: 'USD'})
                .format((activePayment.amount / 100))
            }?`
          "
        />
      </transition>
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import { DebtorPayment } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseInput from './base-input.vue'
import BaseItemDelete from './base-item-delete.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'ItemPaymentDetails',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
    BaseItemDelete,
    BaseToggle
  },

  mixins: [ValidationMixin],

  props: {
    invoiceId: {
      type: String,
      required: true
    }
  },

  async created () {
    // get the debtorpayments
    await this.loadDebtorPayments()
  },

  data () {
    return {
      activePayment: null,
      debtorPayments: [],
      deleting: false,
      paymentMethod: 'check',
      paymentMethodToggles: ['Check', 'ACH'],
      paymentUpdate: null
    }
  },

  computed: {
    saveDisabled () {
      if (!this.activePayment || !this.paymentUpdate) return true
      // the button will be disabled if none of the values are changed
      if (
        moment(this.activePayment.batch_date).startOf('day').toDate().getTime()
        !== moment(this.paymentUpdate.batch_date).startOf('day').toDate().getTime()
        || this.activePayment.check_number !== this.paymentUpdate.check_number
        || this.activePayment.transaction_type !== this.paymentUpdate.transaction_type
      ) {
        return false
      }
      return true
    }
  },

  methods: {
    cancelDelete () {
      this.activePayment = null
      this.deleting = false
    },

    cancelUpdate () {
      this.activePayment = null
      this.debtorPayments.forEach(item => this.$set(item, 'editing', false))
      this.paymentUpdate = null
    },

    closeModal () {
      this.$emit('close')
    },

    async deletePayment () {
      this.deleting = false
      this.progressStart()

      try {
        await DebtorPayment.delete(this.activePayment.id)
        this.activePayment = null
        await this.loadDebtorPayments()
        this.requestSuccess({ message: 'The payment has been deleted' })
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Payment Details "Delete Payment" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error deleting payment', error)
        this.requestFailure({ message: 'There was a problem deleting the payment' })
      }
    },

    async loadDebtorPayments () {
      this.progressStart()
      try {
        this.debtorPayments = (await DebtorPayment.queryList({
          invoice_id: this.invoiceId,
          sort_by: 'created_at',
          sort_direction: 'ASC'
        })).data.rows
        // only allow delete for the most recent payment
        if (this.debtorPayments.length) {
          const lastPayment = this.debtorPayments[this.debtorPayments.length - 1]
          this.$set(lastPayment, 'allowDelete', true)
        }
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Payment Details "Load Debtor Payments" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error loading rules', error)
        this.requestFailure({ message: 'There was a problem loading the debtor payments' })
      }
    },

    openDeletePayment (payment) {
      this.activePayment = payment
      this.deleting = true
    },

    openEditPayment (payment) {
      this.activePayment = payment
      // paymentUpdate is edited in editing state and used in this.saveUpdate()
      this.paymentUpdate = {
        batch_date: moment(payment.batch_date).startOf('day').toDate(),
        check_number: payment.check_number,
        id: payment.id,
        transaction_type: payment.transaction_type
      }
      this.paymentMethod = payment.transaction_type === 'ach'
        ? 'ach'
        : 'check'
      // close any others and open clicked on payment
      this.debtorPayments.forEach(item => {
        if (item.id !== payment.id) this.$set(item, 'editing', false)
        else this.$set(item, 'editing', true)
      })
    },
    async saveUpdate () {
      // validate the batch_date
      if (!this.validation()) return
      this.progressStart()
      try {
        await DebtorPayment.update({
          batch_date: this.paymentUpdate.batch_date,
          check_number: this.paymentMethod === 'ach'
            ? null
            : this.paymentUpdate.check_number,
          id: this.paymentUpdate.id,
          transaction_type: this.paymentUpdate.transaction_type
        })
        await this.loadDebtorPayments()
        this.activePayment = null
        this.paymentUpdate = null
        this.requestSuccess({ message: 'The payment has been updated' })
      }
      catch (error) {
        this.captureSentryEvent(
          'Item Payment Details "Save Update" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error loading rules', error)
        this.requestFailure({ message: 'There was a problem updating the debtor payment' })
      }
    },

    updateTransactionType (paymentMethod) {
      this.paymentMethod = paymentMethod
      const transaction_type = this.paymentMethod === 'ach'
        ? 'ach'
        : 'lockbox'
      this.$set(this.paymentUpdate, 'transaction_type', transaction_type)
    },
    validation () {
      // batch date
      if (!this.isValidBatchDate(this.paymentUpdate.batch_date)) {
        this.alertInfo(this.instructions.BATCH_DATE_INSTRUCTION_LONG)
        return false
      }
      // if the payment method is check, the check number must be valid
      if (
        this.paymentMethod === 'check'
        && (!this.paymentUpdate.check_number
        || !this.isValidCheckNumber(this.paymentUpdate.check_number))
      ) {
        this.alertInfo(this.instructions.CHECK_NUMBER_INSTRUCTION_LONG)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../styles/invoice-items.sass'
@import '../styles/tables'
.ItemPaymentDetails
  width: 100%

  &__action-btn
    padding: .5rem

  &__close-icon-wrapper
    margin: 0 rem(24px) 0 rem(-4px)
    padding: rem(14.25px) 0

  // Keeps the headers aligned with the close button
  // Keeps the top and bottom footer spacing equal
  &__content
    margin: rem(16px) 0

  &__input
    width: rem(60px)

  &__item-border
    padding-bottom: rem(3px)
    border-bottom: $border
</style>